<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <form *ngIf="metaData" class="miles-form padded row application-section" [formGroup]="whoAreYouForm">
        <div class="form-section-border">
            <h3>Physical Features</h3>
        </div>
        <!-- Form Controls -->
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.BodyStructureId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.EyeColorId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.NaturalHairColorId" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.HairTextureId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.SkinTypeId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.RaceId" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Ancestry Information</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" *ngIf="motherAncestries">
                <label class="input-label">
                    <span class="input-scaffold__label">Mother's Side<span style="color: red"> *</span></span>
                </label>
                <multiselect
                    [ngClass]="{ invalid: !motherAncestrySelected && isSaved }"
                    [disabled]="denied"
                    [items]="motherAncestries"
                    placeholder="Select ancestries"
                    [filterMode]="false"
                    (onSelectionChanged)="motherAncestriesChanged($event)"
                    [maxToShowInSelectedText]="'5'"
                    [showSelectAllButtons]="false"
                    [autoClose]="true"
                >
                </multiselect>
            </div>
            <div class="input-scaffold" *ngIf="fatherAncestries">
                <label class="input-label">
                    <span class="input-scaffold__label">Father's Side<span style="color: red"> *</span></span>
                </label>
                <multiselect
                    [ngClass]="{ invalid: !fatherAncestrySelected && isSaved }"
                    [disabled]="denied"
                    [items]="fatherAncestries"
                    placeholder="Select ancestries"
                    [filterMode]="false"
                    (onSelectionChanged)="fatherAncestriesChanged($event)"
                    [maxToShowInSelectedText]="'5'"
                    [showSelectAllButtons]="false"
                    [autoClose]="true"
                >
                </multiselect>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <br />
                <dynamic-field
                    *ngIf="getHasOtherAncestry('Mother') || whoAreYou.OtherAncestryMother"
                    [field]="abstractWhoAreYouControls.OtherAncestryMother"
                    [form]="whoAreYouForm"
                ></dynamic-field>
            </div>
            <div class="input-scaffold">
                <br />
                <dynamic-field
                    *ngIf="getHasOtherAncestry('Father') || whoAreYou.OtherAncestryFather"
                    [field]="abstractWhoAreYouControls.OtherAncestryFather"
                    [form]="whoAreYouForm"
                ></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Educational Information</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.EducationCompletedTypeId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.CollegeMajor" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.HighSchoolGpaId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.CollegeGpaId" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.CurrentOccupation" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Introduce yourself</h3>
        </div>
        <div style="margin-bottom: 10px">This section is an opportunity to introduce yourself to potential recipients.</div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.TalentsAbilities" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.Favorites" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.CharacterDescription" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.SpeaksOtherLanguages" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.WhyInterestedInBecomingSpermDonor" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.Goals" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.HobbiesInterests" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.ExerciseRoutine" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.ExtracurricularActivities" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="form-section-border">
            <h3>Personal Medical History</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 50%">
                <dynamic-field [field]="abstractWhoAreYouControls.HasPlasticSurgery" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HasPlasticSurgery')">
                <dynamic-field [field]="abstractWhoAreYouControls.PlasticSurgeryTypeAndYear" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 50%">
                <dynamic-field [field]="abstractWhoAreYouControls.HaveChildren" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HaveChildren')">
                <dynamic-field [field]="abstractWhoAreYouControls.NumberOfChildren" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.BloodTypeId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.RhFactorId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractWhoAreYouControls.BleedingDisorders" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Psychological Questionnaire</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 50%">
                <dynamic-field [field]="abstractWhoAreYouControls.ExperiencedTraumaticEvent" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('ExperiencedTraumaticEvent')">
                <dynamic-field [field]="abstractWhoAreYouControls.TraumaticEventId" [form]="whoAreYouForm"
                (valueChanges)="onTraumaticEventChanged($event)" (controlCreated)="onTraumaticEventCreated($event)"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold" *ngIf="optionIsSelected('ExperiencedTraumaticEvent') && otherTraumaticEventSelected">
            <dynamic-field [field]="abstractWhoAreYouControls.OtherTraumaticEvent" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.SeriousDepression" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.MoodSwings" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.Anxiety" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.Paranoia" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.UnderPsychiatricCare" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractWhoAreYouControls.TreatedForDrugAlcoholAbuse" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 50%">
                <dynamic-field [field]="abstractWhoAreYouControls.KnownGeneticConditions" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('KnownGeneticConditions')">
                <dynamic-field [field]="abstractWhoAreYouControls.GeneticConditions" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <br />
    </form>
</div>
