import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { MultiselectItem } from '@mt-ng2/multiselect-control';

import { DonorWhoAreYouDynamicControlsPartial } from '@model/partials/donor-who-are-you-partial.form-controls';
import { IDonor } from '@model/interfaces/donor';
import { IDonorWhoAreYou } from '@model/interfaces/donor-who-are-you';
import { IDonorAncestry } from '@model/interfaces/donor-ancestry';
import { IAncestry } from '@model/interfaces/ancestry';
import { Ancestries } from '@model/enums/ancestries.enum';
import { DonorRelationshipTypes } from '@model/enums/donor-relationship-types.enum';
import { TraumaticEvents } from '@model/enums/traumatic-events.enum';

import { DonorWhoAreYouService, IWhoAreYouMetaItems } from '../services/donor-who-are-you.service';

@Component({
    selector: 'who-are-you',
    templateUrl: './who-are-you.component.html',
})
export class WhoAreYouComponent implements OnInit, OnDestroy {
    @Input() donor: IDonor;
    @Output() onFormReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output('onFormValueChanges') onFormValueChanges: EventEmitter<IDonorWhoAreYou> = new EventEmitter<IDonorWhoAreYou>();

    whoAreYou: IDonorWhoAreYou;

    // abstract controls
    abstractWhoAreYouControls: any;
    whoAreYouForm: FormGroup;
    metaData: IWhoAreYouMetaItems;
    doubleClickIsDisabled = false;
    formCreated = false;
    motherAncestries: MultiselectItem[];
    fatherAncestries: MultiselectItem[];

    subs = new Subscription();
    isSaved: boolean;

    otherTraumaticEventSelected: boolean;

    get fatherAncestrySelected(): boolean {
        return this.fatherAncestries.some((itm) => itm.Selected);
    }

    get motherAncestrySelected(): boolean {
        return this.fatherAncestries.some((itm) => itm.Selected);
    }

    constructor(
        private fb: FormBuilder,
        private whoAreYouService: DonorWhoAreYouService,
        private cdr: ChangeDetectorRef,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.whoAreYouForm.dirty) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        forkJoin([this.whoAreYouService.whoAreYouAdditionalParameters(), this.whoAreYouService.getById(this.donor.Id)]).subscribe(([metaData, whoAreYou]) => {
            this.whoAreYou = whoAreYou;
            this.metaData = metaData;
            this.motherAncestries = metaData.ancestries.map(
                (a) =>
                    new MultiselectItem(
                        a,
                        this.whoAreYou.DonorAncestries.filter((anc) => anc.RelationshipTypeId === DonorRelationshipTypes.Mother).some((anc) => anc.AncestryId === a.Id),
                    ),
            );
            this.fatherAncestries = metaData.ancestries.map(
                (a) =>
                    new MultiselectItem(
                        a,
                        this.whoAreYou.DonorAncestries.filter((anc) => anc.RelationshipTypeId === DonorRelationshipTypes.Father).some((anc) => anc.AncestryId === a.Id),
                    ),
            );
            this.createForm();
            this.otherTraumaticEventSelected = this.whoAreYou.TraumaticEventId && this.whoAreYou.TraumaticEventId === TraumaticEvents.Other;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    getHasOtherAncestry(type: string): boolean {
        const control = this.whoAreYouForm.get(`DonorWhoAreYou.${type}Ancestries`);
        return control ? control.value.some((a: IAncestry) => a.Id === Ancestries.Other) : false;
    }

    optionIsSelected(controlName: string): boolean {
        const control = this.whoAreYouForm.get(`DonorWhoAreYou.${controlName}`);
        return control && control.value;
    }

    onTraumaticEventCreated(event: any): void {
        this.onTraumaticEventChanged(event.value);
    }

    onTraumaticEventChanged(eventId: number): void {
        this.otherTraumaticEventSelected = eventId === TraumaticEvents.Other;
        this.cdr.detectChanges();
    }

    createForm(): void {
        this.getControls();
        this.whoAreYouForm = this.assignFormGroups();
        this.formCreated = true;
        this.subs.add(
            this.whoAreYouForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                if (this.whoAreYouForm.dirty) {
                    this.assignFormValues();
                    this.onFormValueChanges.emit(this.whoAreYou);
                }
            }),
        );
        this.onFormReady.emit(this.whoAreYouForm);
    }

    getControls(): void {
        this.abstractWhoAreYouControls = new DonorWhoAreYouDynamicControlsPartial(this.whoAreYou, this.metaData).Form;
    }

    fatherAncestriesChanged(evt): void {
        this.whoAreYouForm.get('DonorWhoAreYou.FatherAncestries').setValue(evt.selectedItems);
        this.whoAreYouForm.get('DonorWhoAreYou.FatherAncestries').markAsDirty();
        this.whoAreYouForm.get('DonorWhoAreYou.FatherAncestries').markAsTouched();
    }

    motherAncestriesChanged(evt): void {
        this.whoAreYouForm.get('DonorWhoAreYou.MotherAncestries').setValue(evt.selectedItems);
        this.whoAreYouForm.get('DonorWhoAreYou.MotherAncestries').markAsDirty();
        this.whoAreYouForm.get('DonorWhoAreYou.MotherAncestries').markAsTouched();
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            DonorWhoAreYou: this.fb.group({
                FatherAncestries: this.fb.control(
                    this.fatherAncestries.filter((fa) => fa.Selected).map((i) => i.Item),
                    [Validators.required],
                ),
                MotherAncestries: this.fb.control(
                    this.motherAncestries.filter((ma) => ma.Selected).map((i) => i.Item),
                    [Validators.required],
                ),
            }),
        });
    }

    assignFormValues(): void {
        Object.assign(this.whoAreYou, this.whoAreYouForm.get('DonorWhoAreYou').value);
        let fatherAncestries = this.whoAreYouForm.get('DonorWhoAreYou.FatherAncestries').value.map((fa) => this.buildDonorAncestry(fa, DonorRelationshipTypes.Father));
        let motherAncestries = this.whoAreYouForm.get('DonorWhoAreYou.MotherAncestries').value.map((ma) => this.buildDonorAncestry(ma, DonorRelationshipTypes.Mother));
        this.whoAreYou.DonorAncestries = [...fatherAncestries, ...motherAncestries];
    }

    buildDonorAncestry(ancestry: IAncestry, relationId: DonorRelationshipTypes): IDonorAncestry {
        return {
            AncestryId: ancestry.Id,
            DonorWhoAreYouId: this.donor.Id,
            RelationshipTypeId: relationId,
        };
    }
}
