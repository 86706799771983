<div *ngIf="viewReady">
    <div *ngIf="!isEditing">
        <span
            style="font-size: 18px; color: #095093; font-weight: bold"
            class="pull-right"
            *ngIf="canEdit"
            [style.cursor]="canEdit ? 'pointer' : 'default'"
        >
            <i class="fa fa-lg fa-edit" (click)="edit()"></i>
            <span *ngIf="canDelete">
                &nbsp;
                <i class="fa fa-lg fa-trash" (click)="showDelete = true"></i>
            </span>
        </span>
        <div class="display-padded" *ngIf="inventory.Unsellable">
            <b style="color: red">UNSELLABLE</b>
        </div>
        <div class="display-padded" *ngIf="inventory">
            <span><label>Donation Date: </label> {{ inventory.DonorSample.SampleDate | date: medium }}</span
            ><br />
            <span><label>Donation Status: </label> {{ inventory.DonorSample.DonationStatus.Name }}</span>
        </div>
        <app-dynamic-view [config]="config.viewOnly"></app-dynamic-view>
        <div *ngIf="inventoryPosition" class="display-padded">
            <div>
                <span><label>Inventory Position:</label></span>
            </div>
            <span><b>Tank:</b> {{ inventoryPosition.Tank.Name }} - {{ inventoryPosition.Tank.Number }}</span>
            <span><b>Can:</b> {{ inventoryPosition.Can.Number }}</span>
            <span><b>Cane:</b> {{ inventoryPosition.Cane.Number }}</span>
        </div>
    </div>
    <div *ngIf="isEditing">
        <label>Donation Date: </label><span> {{ donation.SampleDate | date: medium }}</span
        ><br />
        <label>Donation Status: </label><span> {{ donation.DonationStatus.Name }}</span>
        <app-dynamic-form [config]="config.formObject" ([isEditing])="(isEditing)" (submitted)="formSubmitted($event)">
            <br />
            <app-inventory-vessel
                [position]="inventoryPosition"
                [canCreate]="canEdit"
                (onCaneSelected)="inventoryPosition = $event"
            ></app-inventory-vessel>
            <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickIsDisabled" Class="btn btn-flat btn-success">
                Save
            </button>
            <button type="button" Class="btn btn-flat btn-default" (click)="cancelClick()">Cancel</button>
        </app-dynamic-form>
    </div>
</div>
<!-- Confirm Modal -->
<modal-wrapper *ngIf="showConfirm" [options]="confirmOptions">
    <div>
        <h2>Inventory Summary</h2>
        <p><b>Vial Type:</b> {{ getPrepMethodName(inventory.SamplePrepMethodId) }}</p>
        <p><b>Tank:</b> {{ inventoryPosition.Tank.Name }} - {{ inventoryPosition.Tank.Number }}</p>
        <p><b>Can:</b> {{ inventoryPosition.Can.Number }}</p>
        <p><b>Cane:</b> {{ inventoryPosition.Cane.Number }}</p>
        <p>Vial number will be auto serialized</p>
    </div>
    <div class="row col-md-12">
        <button (click)="saveEggInventory()" class="btn btn-flat btn-success">Save</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="cancelSave()">Cancel</button>
    </div>
</modal-wrapper>

<!-- Delete Modal -->
<modal-wrapper *ngIf="showDelete" [options]="deleteOptions" (cancelClick)="showDelete = false">
    <div>
        <h3>Are you sure you want to delete this inventory? This cannot be undone.</h3>
        <p><b>Vial Type:</b> {{ getPrepMethodName(inventory.SamplePrepMethodId) }}</p>
        <p><b>Tank:</b> {{ inventoryPosition.Tank.Name }} - {{ inventoryPosition.Tank.Number }}</p>
        <p><b>Can:</b> {{ inventoryPosition.Can.Number }}</p>
        <p><b>Cane:</b> {{ inventoryPosition.Cane.Number }}</p>
    </div>
    <br />
    <div class="row col-md-12">
        <button (click)="deleteInventory()" class="btn btn-flat btn-danger">Delete</button>
        <button type="button" Class="btn btn-flat btn-default" (click)="showDelete = false">Cancel</button>
    </div>
</modal-wrapper>
