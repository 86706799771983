<div *ngIf="!isEditing && donor.DonorBasicInfo">
    <h4>
        Basic Info
    </h4>
    <div>
        <div class="display-padded">
            <b>First Name:</b>
            <span>{{ donor.DonorUser.FirstName }}</span>
        </div>
        <div class="display-padded">
            <b>Last Name:</b>
            <span>{{ donor.DonorUser.LastName }}</span>
        </div>
        <div class="display-padded">
            <b>Phone Number:</b>
            <span>{{ donor.DonorBasicInfo.ApplicantPhoneNumber }}</span>
        </div>
        <div class="display-padded">
            <b>Has Text Communication:</b>
            <span>{{ donor.DonorBasicInfo.HasTextCommunication | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Date of Birth:</b>
            <span>{{ donor.DonorBasicInfo.Dob | date }}</span>
        </div>
        <div class="display-padded">
          <span><b>Is US Citizen: </b></span>
          <span>{{ donor.DonorBasicInfo.IsUsCitizen | yesNoPipe }}</span>
        </div>
        <div class="display-padded">
            <b>Gender:</b>
            <span>{{ donor.DonorBasicInfo.Gender?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Height:</b>
            <span>{{ adjustedHeight }}</span>
        </div>
        <div class="display-padded">
            <b>Height Unit:</b>
            <span>{{ donor.DonorBasicInfo.HeightUnit?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Weight:</b>
            <span>{{ donor.DonorBasicInfo.Weight }}</span>
        </div>
        <div class="display-padded">
            <b>Weight Unit:</b>
            <span>{{ donor.DonorBasicInfo.WeightUnit?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Comment:</b>
            <span>{{ donor.DonorBasicInfo.Comment }}</span>
        </div>
        <div class="display-padded">
            <b>Referral Type:</b>
            <span>{{ donor.DonorBasicInfo.ReferralType?.Name }}</span>
        </div>
        <div class="display-padded">
            <b>Completed:</b>
            <span>{{ donor.DonorBasicInfo.Completed | date }}</span>
        </div>
        <div class="display-padded">
            <b>Date Created:</b>
            <span>{{ donor.DonorBasicInfo.DateCreated | date }}</span>
        </div>
        <div class="display-padded">
            <b>Date Modified:</b>
            <span>{{ donor.DonorUser.DateModified | date }}</span>
        </div>
        <app-donor-addresses-details [donorAddresses]="donor.DonorBasicInfo.DonorAddresses"></app-donor-addresses-details>
    </div>
</div>
<div *ngIf="isEditing">
    <h4>Donor Info</h4>
    <app-donor-application-basic-form
    [donor]="donor"
    (onFormValueChanges)="onFormValueChanges($event)"
    ></app-donor-application-basic-form>
    <div>
        <button [disabled]="!formChanged" class="btn btn-success btn-flat" (click)="onSubmit()">
            Save
        </button>
        <button class="btn btn-default btn-flat" (click)="cancelClick()">
            Cancel
        </button>
    </div>
</div>
