import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, shareReplay, startWith, tap } from 'rxjs/operators';

import { ICountry, IDynamicFormConfig, IState } from '@mt-ng2/dynamic-form';
import { IMetaItem } from '@mt-ng2/dynamic-form/libraries/interfaces/meta-item';

import { IDonorBasicInfoDynamicControlsParameters } from '@model/form-controls/donor-basic-info.form-controls';
import { DonorAddressDynamicControls, IDonorAddressDynamicControlsParameters } from '@model/form-controls/donor-address.form-controls';
import { IDonorBasicInfo } from '@model/interfaces/donor-basic-info';
import { DonorBasicInfoDynamicControls } from '@model/partials/donor-basic-info.form-controls';
import { AddressDynamicControls, IAddressDynamicControlsParameters } from '@model/partials/address.form-controls';
import { IDonor } from '@model/interfaces/donor';
import { AddressTypes } from '@model/enums/address-types.enum';
import { IDonorAddress } from '@model/interfaces/donor-address';
import { UnitsOfMeasurement } from '@model/enums/units-of-measurement.enum';

import { BasicInfoService } from '../services/basic-info.service';
import { calculateHeight } from '../../libraries/parse-height.library';
import { IAddress } from '@model/interfaces/address';

@Component({
    selector: 'app-donor-application-basic-form',
    templateUrl: './donor-application-basic-form.component.html',
})
export class DonorApplicationBasicFormComponent implements OnInit {
    @Input() donor: IDonor;
    @Output() onFormReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output('onFormValueChanges') onFormValueChanges: EventEmitter<IDonorBasicInfo> = new EventEmitter<IDonorBasicInfo>();

    donorBasicInfo: IDonorBasicInfo;
    primaryAddress: IDonorAddress;
    currentAddress: IDonorAddress;
    form: FormGroup;

    basicInfoControls: DonorBasicInfoDynamicControls;
    donorAddressControls: DonorAddressDynamicControls;
    addressControls: AddressDynamicControls;
    currentAddressControls: AddressDynamicControls;

    basicAdditionalParameters: IDonorBasicInfoDynamicControlsParameters;
    donorAddressAddtionalParameters: IDonorAddressDynamicControlsParameters;
    addressAdditionalParameters: IAddressDynamicControlsParameters;

    doubleClickIsDisabled = false;
    hideComments$: Observable<boolean>;
    showStates$: Observable<boolean>;
    showStatesCurrentAddress$: Observable<boolean>;
    showImperialHeight$: Observable<boolean>;

    commentLabel: string;

    config: IDynamicFormConfig;

    subs = new Subscription();
    constructor(private cdr: ChangeDetectorRef, private fb: FormBuilder, private basicInfoService: BasicInfoService) {}

    ngOnInit(): void {
        forkJoin({
            addressAdditionalParameters: this.basicInfoService.addressAdditionalParameters$,
            basicAdditionalParameters: this.basicInfoService.getBasicAdditionalParameters(),
            basicInfo: this.basicInfoService.getById(this.donor.Id),
            donorAddressAddtionalParameters: this.basicInfoService.donorAddressAddtionalParameters$,
        }).subscribe((x) => {
            this.basicAdditionalParameters = x.basicAdditionalParameters;
            this.donorAddressAddtionalParameters = x.donorAddressAddtionalParameters;
            this.addressAdditionalParameters = x.addressAdditionalParameters;
            this.donorBasicInfo = x.basicInfo;
            this.initForm();
        });
        this.subs.add(this.basicInfoService.changeEmitted$.subscribe((basicInfo) => {
            // necessary to get addressIds for subsequent edits
            this.form = null;
            this.cdr.detectChanges();
            this.donorBasicInfo = basicInfo;
            this.initForm();
        }));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    initForm(): void {
        this.form = this.assignFormGroups();
        this.setAddresses();
        this.createControls();
        this.cdr.detectChanges();
        this.setClearOnUnitChange();
        this.subs.add(
            this.form.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                if (this.form.dirty) {
                    this.normalizeData();
                    this.onFormValueChanges.emit(this.donorBasicInfo);
                }
            }),
        );
        this.onFormReady.emit(this.form);
    }

    createControls(): void {
        this.basicInfoControls = new DonorBasicInfoDynamicControls(this.donorBasicInfo ?? <IDonorBasicInfo>{}, this.basicAdditionalParameters);
        const address = this.primaryAddress ? this.primaryAddress : this.currentAddress;
        this.addressControls = this.createAddressControls(address?.Address ?? <IAddress>{}, 'Address');
    }

    setAddresses(): void {
        const addresses = this.donorBasicInfo.DonorAddresses;
        this.currentAddress = addresses.find((a) => a.AddressTypeId === AddressTypes.Current);
        this.primaryAddress = addresses.find((a) => a.AddressTypeId === AddressTypes.Primary);
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            DonorBasicInfo: this.fb.group({}),
        });
    }

    createAddressControls(value: IAddress | null, formGroup: string): AddressDynamicControls {
        const {countries, states} = this.addressAdditionalParameters;
        if (value) {
            value.CountryCode = value.CountryCode
            ? (<(IMetaItem & ICountry)[]>countries).find((country) => country.CountryCode === value.CountryCode).Id
            : null;
            value.StateCode = value.StateCode
            ? (<(IMetaItem & IState)[]>states).find((state) => state.StateCode === value.StateCode).Id
            : null;
        }
        this.form.addControl(formGroup, this.fb.group({}));
        return new AddressDynamicControls(value ?? <IAddress>{}, { formGroup, ...this.addressAdditionalParameters });
    }

    removeCurrentAddressControls(): void {
        this.form.removeControl('CurrentAddress');
    }

    setShowComment(ReferralTypeId: AbstractControl): void {
        const referralTypes = this?.basicAdditionalParameters?.referralTypes;
        this.hideComments$ = ReferralTypeId.valueChanges.pipe(
            startWith(ReferralTypeId.value),
            map((id) => referralTypes?.find((t) => t.Id === id)),
            tap((rType) => this.commentLabel = rType?.HasComments ? rType?.Placeholder : ''),
            map((rType) => !rType?.HasComments),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    setShowStates(countryCodeControl: AbstractControl): void {
        this.showStates$ = countryCodeControl?.valueChanges.pipe(
            startWith(countryCodeControl.value ?? 0),
            map((value) => value === 0),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    setShowStatesCurrentAddress(countryCodeControl: AbstractControl): void {
        this.showStatesCurrentAddress$ = countryCodeControl?.valueChanges.pipe(
            startWith(countryCodeControl.value ?? 0),
            map((value) => value === 0),
            distinctUntilChanged(),
            shareReplay(1),
        );
    }

    setClearOnUnitChange(): void {
        const form = <FormGroup> this?.form?.controls?.DonorBasicInfo;
        const { WeightUnitId, Weight } = form?.controls;
        WeightUnitId?.valueChanges.pipe(distinctUntilChanged()).subscribe(() => Weight.reset());
    }

    setShowImperialHeight(heightUnitIdControl: AbstractControl): void {

        this.showImperialHeight$ = heightUnitIdControl.valueChanges.pipe(
            startWith(heightUnitIdControl.value ?? null),
            map((value) => value === UnitsOfMeasurement.FeetInches),
            distinctUntilChanged(),
            tap((val) => {
                const form = <FormGroup> this?.form?.controls?.DonorBasicInfo;
                const { Feet, Inches, Meters } = form?.controls;
                if (val) {
                    Meters?.reset();
                } else {
                    Feet?.reset();
                    Inches?.reset();
                }
            }),
            shareReplay(1),
        );
    }

    setAddCurrentAddress(isCurrentAddressControl: AbstractControl): void {
        if (this.currentAddress && this.primaryAddress) { isCurrentAddressControl.patchValue(false); }
        isCurrentAddressControl.valueChanges
            .pipe(
                startWith(isCurrentAddressControl.value),
                tap((x) => {
                    this.form.markAsDirty();
                    if (x) {
                        this.currentAddressControls = null;
                        this.removeCurrentAddressControls();
                    } else {
                        const address = this.primaryAddress ? this.currentAddress : null;
                        this.currentAddressControls = this.createAddressControls(address?.Address || null, 'CurrentAddress');
                    }
                    this.cdr.detectChanges();
                }),
            )
            .subscribe();
    }

    normalizeData(): void {
        let form = this.form.value;
        Object.assign(this.donorBasicInfo, form.DonorBasicInfo);
        calculateHeight(this.donorBasicInfo);
        if (form.CurrentAddress) {
            this.donorBasicInfo.DonorAddresses = form.Address ? [ this.buildDonorAddress(Object.assign({}, this.primaryAddress?.Address, form.Address), AddressTypes.Primary, this.donorBasicInfo.Id)] : [];
            this.donorBasicInfo.DonorAddresses.push(this.buildDonorAddress(Object.assign({}, this.currentAddress?.Address, form.CurrentAddress), AddressTypes.Current, this.donorBasicInfo.Id));
        } else {
            this.donorBasicInfo.DonorAddresses = form.Address ? [ this.buildDonorAddress(Object.assign({}, this.primaryAddress?.Address, form.Address), AddressTypes.Current, this.donorBasicInfo.Id)] : [];
        }
    }

    buildDonorAddress(address: IAddress, type: AddressTypes, basicId: number): IDonorAddress {
        const {countries, states} = this.addressAdditionalParameters;
        return {
            Address: {...address,
                CountryCode: address?.CountryCode || (address?.CountryCode as any) === 0 ? (<(IMetaItem & ICountry)[]>countries).find((c) => c.Id === address.CountryCode).CountryCode : null,
                StateCode: address?.StateCode || (address?.StateCode as any) === 0 ? (<(IMetaItem & IState)[]>states).find((c) => c.Id === address.StateCode).StateCode : null,
            },
            AddressId: address.Id || 0,
            AddressTypeId: type,
            DonorBasicInfoId: basicId,
        };
    }
}
