import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IThawing } from '../interfaces/thawing';
import { IClinic } from '../interfaces/clinic';
import { IFollowUpReason } from '../interfaces/follow-up-reason';
import { ISamplePrepMethod } from '../interfaces/sample-prep-method';
import { ISalesOrder } from '../interfaces/sales-order';

export interface IThawingDynamicControlsParameters {
    formGroup?: string;
    salesOrders?: ISalesOrder[];
    clinics?: IClinic[];
    procedureTypes?: ISamplePrepMethod[];
    vialTypes?: ISamplePrepMethod[];
    followUpReasons?: IFollowUpReason[];
}

export class ThawingDynamicControls {

    formGroup: string;
    salesOrders: ISalesOrder[];
    clinics: IClinic[];
    procedureTypes: ISamplePrepMethod[];
    vialTypes: ISamplePrepMethod[];
    followUpReasons: IFollowUpReason[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private thawing?: IThawing, additionalParameters?: IThawingDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Thawing';
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;
        this.clinics = additionalParameters && additionalParameters.clinics || undefined;
        this.procedureTypes = additionalParameters && additionalParameters.procedureTypes || undefined;
        this.vialTypes = additionalParameters && additionalParameters.vialTypes || undefined;
        this.followUpReasons = additionalParameters && additionalParameters.followUpReasons || undefined;

        this.Form = {
            ActualThawingDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Actual Thawing Date',
                name: 'ActualThawingDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.ActualThawingDate || null,
            }),
            ClinicId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Clinic',
                name: 'ClinicId',
                options: this.clinics,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.ClinicId || null,
            }),
            Comments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comments',
                name: 'Comments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.thawing && this.thawing.hasOwnProperty('Comments') && this.thawing.Comments !== null ? this.thawing.Comments.toString() : '',
            }),
            Concentration: new DynamicField({
                formGroup: this.formGroup,
                label: 'Concentration',
                name: 'Concentration',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.Concentration || null,
            }),
            FollowUpDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Follow Up Date',
                name: 'FollowUpDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.FollowUpDate || null,
            }),
            FollowUpReasonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Follow Up Reason',
                name: 'FollowUpReasonId',
                options: this.followUpReasons,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.FollowUpReasonId || null,
            }),
            GuaranteeMet: new DynamicField({
                formGroup: this.formGroup,
                label: 'Guarantee Met',
                name: 'GuaranteeMet',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.hasOwnProperty('GuaranteeMet') && this.thawing.GuaranteeMet !== null ? this.thawing.GuaranteeMet : false,
            }),
            IsFinalized: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Finalized',
                name: 'IsFinalized',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.hasOwnProperty('IsFinalized') && this.thawing.IsFinalized !== null ? this.thawing.IsFinalized : false,
            }),
            Motility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Motility',
                name: 'Motility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.Motility || null,
            }),
            ProcedureDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Procedure Date',
                name: 'ProcedureDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.ProcedureDate || null,
            }),
            ProcedureTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Procedure Type',
                name: 'ProcedureTypeId',
                options: this.procedureTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.ProcedureTypeId || null,
            }),
            ReasonNotToCountId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reason Not To Count',
                name: 'ReasonNotToCountId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.ReasonNotToCountId || null,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.thawing && this.thawing.SalesOrderId || null,
            }),
            Technician: new DynamicField({
                formGroup: this.formGroup,
                label: 'Technician',
                name: 'Technician',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.thawing && this.thawing.hasOwnProperty('Technician') && this.thawing.Technician !== null ? this.thawing.Technician.toString() : '',
            }),
            TreatingPhysician: new DynamicField({
                formGroup: this.formGroup,
                label: 'Treating Physician',
                name: 'TreatingPhysician',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.thawing && this.thawing.hasOwnProperty('TreatingPhysician') && this.thawing.TreatingPhysician !== null ? this.thawing.TreatingPhysician.toString() : '',
            }),
            VialsThawed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vials Thawed',
                name: 'VialsThawed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.VialsThawed || null,
            }),
            VialsUtilized: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vials Utilized',
                name: 'VialsUtilized',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.VialsUtilized || null,
            }),
            VialTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vial Type',
                name: 'VialTypeId',
                options: this.vialTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.VialTypeId || null,
            }),
            Volume: new DynamicField({
                formGroup: this.formGroup,
                label: 'Volume',
                name: 'Volume',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
                validation: [  ],
                validators: {  },
                value: this.thawing && this.thawing.Volume || null,
            }),
        };

        this.View = {
            ActualThawingDate: new DynamicLabel(
                'Actual Thawing Date',
                this.thawing && this.thawing.ActualThawingDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ClinicId: new DynamicLabel(
                'Clinic',
                getMetaItemValue(this.clinics, this.thawing && this.thawing.hasOwnProperty('ClinicId') && this.thawing.ClinicId !== null ? this.thawing.ClinicId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Comments: new DynamicLabel(
                'Comments',
                this.thawing && this.thawing.hasOwnProperty('Comments') && this.thawing.Comments !== null ? this.thawing.Comments.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Concentration: new DynamicLabel(
                'Concentration',
                this.thawing && this.thawing.Concentration || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            FollowUpDate: new DynamicLabel(
                'Follow Up Date',
                this.thawing && this.thawing.FollowUpDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            FollowUpReasonId: new DynamicLabel(
                'Follow Up Reason',
                getMetaItemValue(this.followUpReasons, this.thawing && this.thawing.hasOwnProperty('FollowUpReasonId') && this.thawing.FollowUpReasonId !== null ? this.thawing.FollowUpReasonId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            GuaranteeMet: new DynamicLabel(
                'Guarantee Met',
                this.thawing && this.thawing.hasOwnProperty('GuaranteeMet') && this.thawing.GuaranteeMet !== null ? this.thawing.GuaranteeMet : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsFinalized: new DynamicLabel(
                'Is Finalized',
                this.thawing && this.thawing.hasOwnProperty('IsFinalized') && this.thawing.IsFinalized !== null ? this.thawing.IsFinalized : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Motility: new DynamicLabel(
                'Motility',
                this.thawing && this.thawing.Motility || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
            ProcedureDate: new DynamicLabel(
                'Procedure Date',
                this.thawing && this.thawing.ProcedureDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            ProcedureTypeId: new DynamicLabel(
                'Procedure Type',
                getMetaItemValue(this.procedureTypes, this.thawing && this.thawing.hasOwnProperty('ProcedureTypeId') && this.thawing.ProcedureTypeId !== null ? this.thawing.ProcedureTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReasonNotToCountId: new DynamicLabel(
                'Reason Not To Count',
                this.thawing && this.thawing.ReasonNotToCountId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.thawing && this.thawing.hasOwnProperty('SalesOrderId') && this.thawing.SalesOrderId !== null ? this.thawing.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Technician: new DynamicLabel(
                'Technician',
                this.thawing && this.thawing.hasOwnProperty('Technician') && this.thawing.Technician !== null ? this.thawing.Technician.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            TreatingPhysician: new DynamicLabel(
                'Treating Physician',
                this.thawing && this.thawing.hasOwnProperty('TreatingPhysician') && this.thawing.TreatingPhysician !== null ? this.thawing.TreatingPhysician.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            VialsThawed: new DynamicLabel(
                'Vials Thawed',
                this.thawing && this.thawing.VialsThawed || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            VialsUtilized: new DynamicLabel(
                'Vials Utilized',
                this.thawing && this.thawing.VialsUtilized || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            VialTypeId: new DynamicLabel(
                'Vial Type',
                getMetaItemValue(this.vialTypes, this.thawing && this.thawing.hasOwnProperty('VialTypeId') && this.thawing.VialTypeId !== null ? this.thawing.VialTypeId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Volume: new DynamicLabel(
                'Volume',
                this.thawing && this.thawing.Volume || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 1,
                }),
            ),
        };

    }
}
