import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, InputTypes, SelectInputTypes } from '@mt-ng2/dynamic-form';

import { IDonorSample } from '../interfaces/donor-sample';
import {
    DonorTestSampleDynamicControlsPartial,
    IDonorTestSampleDynamicControlsPartialParameters,
} from '@model/partials/donor-test-sample-partial.form-controls';
import { DateParts } from '@mt-ng2/date-module';
import { ISamplePrepMethod } from '@model/interfaces/sample-prep-method';
import { DonationStatuses } from '@model/enums/donation-statuses.enum';
import { getUserFullName } from '@angular-common/libraries/common-functions.library';
import { DatePipe } from '@angular/common';
import { IMetaItem } from '@mt-ng2/base-service';
import { FormYesNoButtonComponent } from '@angular-common/components/form-yes-no-button.component';
import { MONTHS_TO_RELEASE_DATE as monthsToReleaseDate } from '../../admin-portal/donors/donor-donations/donor-donations.library';

export interface IDonorDonationSampleDynamicControlsPartialParameters extends IDonorTestSampleDynamicControlsPartialParameters {
    initialScreeningTestingsMetaItem?: IMetaItem[];
    followUpScreeningTestingsMetaItem?: IMetaItem[];
    preQuarantineTestingsMetaItem?: IMetaItem[];
    quarantineReleaseTestingsMetaItem?: IMetaItem[];
}

export class DonorDonationSampleDynamicControlsPartial extends DonorTestSampleDynamicControlsPartial {
    datePipe = new DatePipe('en-US');
    constructor(donorSamplePartial?: IDonorSample, additionalParameters?: IDonorDonationSampleDynamicControlsPartialParameters) {
        super(donorSamplePartial, additionalParameters);

        (<DynamicField>this.Form.SampleDate).labelHtml = '<label>Donation Provided Date</label>';
        (<DynamicLabel>this.View.SampleDate).label = 'Donation Provided Date';
        (<DynamicField>this.Form.HasRemainder).labelHtml = '<label>Create Remainder Vials?</label>';
        (<DynamicLabel>this.View.HasRemainder).label = 'Create Remainder Vials?';
        (<DynamicField>this.Form.SampleStatusId).labelHtml = '<label>Lab Status</label>';
        (<DynamicLabel>this.View.SampleStatusId).label = 'Lab Status';
        (<DynamicField>this.Form.DonationStatusId).labelHtml = '<label>Donation Status</label>';
        (<DynamicField>this.Form.DonationStatusId).value = donorSamplePartial?.DonationStatusId
            ? donorSamplePartial.DonationStatusId
            : DonationStatuses.Quarantine;
        (<DynamicLabel>this.View.DonationStatusId).label = 'Donation Status';

        (<DynamicField>this.Form.EligibleQuarantineReleaseDate) = new DynamicField({
            disabled: true,
            formGroup: this.formGroup,
            label: 'Eligible Quarantine Release Date',
            name: 'EligibleQuarantineReleaseDate',
            options: null,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
                scale: null,
            }),
            value: (donorSamplePartial && new Date(donorSamplePartial.SampleDate).mtDate.add(monthsToReleaseDate, DateParts.months).date) || null,
        });
        (<DynamicLabel>this.View.EligibleQuarantineReleaseDate) = new DynamicLabel(
            'Eligible Quarantine Release Date',
            (donorSamplePartial && new Date(donorSamplePartial.SampleDate).mtDate.add(monthsToReleaseDate, DateParts.months).date) || null,
            new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.Datepicker,
                scale: null,
            }),
        );

        (<DynamicLabel>this.View.AbbreviatedScreeningComplete).valueHtml = donorSamplePartial.AbbreviatedScreeningComplete
            ? `Yes<br/>${
                  donorSamplePartial.AbbreviatedScreeningModifiedBy
                      ? `<p style="margin:0"><em>Modified By ${getUserFullName(
                            donorSamplePartial.AbbreviatedScreeningModifiedBy,
                        )} ${this.datePipe.transform(donorSamplePartial.AbbreviatedScreeningModifiedDate, 'short')}</em></p>`
                      : ''
              }`
            : `No<br/>${
                  donorSamplePartial.AbbreviatedScreeningModifiedBy
                      ? `<p style="margin:0"><em>Modified By ${getUserFullName(
                            donorSamplePartial.AbbreviatedScreeningModifiedBy,
                        )} ${this.datePipe.transform(donorSamplePartial.AbbreviatedScreeningModifiedDate, 'short')}</em></p>`
                      : ''
              }`;

        (<DynamicField>this.Form.InitialScreeningTestingId).options = additionalParameters.initialScreeningTestingsMetaItem;
        (<DynamicField>this.Form.FollowUpScreeningTestingId).options = additionalParameters.followUpScreeningTestingsMetaItem;
        (<DynamicField>this.Form.PreQuarantineTestingId).options = additionalParameters.preQuarantineTestingsMetaItem;
        (<DynamicField>this.Form.QuarantineReleaseTestingId).options = additionalParameters.quarantineReleaseTestingsMetaItem;
        (<DynamicField>this.Form.InitialScreeningTestingId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.FollowUpScreeningTestingId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.PreQuarantineTestingId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicField>this.Form.QuarantineReleaseTestingId).type.inputType = SelectInputTypes.Dropdown;
        (<DynamicLabel>this.View.InitialScreeningTestingId).value = donorSamplePartial.InitialScreeningTesting
            ? this.formatDateField(donorSamplePartial.InitialScreeningTesting.BloodDrawDate)
            : null;
        (<DynamicLabel>this.View.FollowUpScreeningTestingId).value = donorSamplePartial.FollowUpScreeningTesting
            ? this.formatDateField(donorSamplePartial.FollowUpScreeningTesting.BloodDrawDate)
            : null;
        (<DynamicLabel>this.View.PreQuarantineTestingId).value = donorSamplePartial.PreQuarantineTesting
            ? this.formatDateField(donorSamplePartial.PreQuarantineTesting.BloodDrawDate)
            : null;
        (<DynamicLabel>this.View.QuarantineReleaseTestingId).value = donorSamplePartial.QuarantineReleaseTesting
            ? this.formatDateField(donorSamplePartial.QuarantineReleaseTesting.BloodDrawDate)
            : null;
        (<DynamicField>this.Form.TissueEligibleForTransfer).component = FormYesNoButtonComponent;
        (<DynamicField>this.Form.PreQuarantineTestingId).labelHtml = '<label>Initial Screening and Testing Date 1</label>';
        (<DynamicField>this.Form.InitialScreeningTestingId).labelHtml = '<label>Initial Screening and Testing Date 2</label>';
        (<DynamicField>this.Form.QuarantineReleaseTestingId).labelHtml = '<label>Quarantine Release Screening and Testing Date 1</label>';
        (<DynamicField>this.Form.FollowUpScreeningTestingId).labelHtml = '<label>Quarantine Release Screening and Testing Date 2</label>';
        (<DynamicLabel>this.View.PreQuarantineTestingId).label = 'Initial Screening and Testing Date 1';
        (<DynamicLabel>this.View.InitialScreeningTestingId).label = 'Initial Screening and Testing Date 2';
        (<DynamicLabel>this.View.QuarantineReleaseTestingId).label = 'Quarantine Release Screening and Testing Date 1';
        (<DynamicLabel>this.View.FollowUpScreeningTestingId).label = 'Quarantine Release Screening and Testing Date 2';
    }

    formatDateField(date: Date): string {
        return new Date(date).mtDate.format('MMM D, yyyy');
    }

    getPrepMethodMultiselectValue(value: ISamplePrepMethod[], options: any[]): number[] {
        const prepMethodIds = value.map((itm) => itm.Id);
        return options.filter((itm) => prepMethodIds.includes(itm.Id)).map((itm) => itm.Id);
    }
}
