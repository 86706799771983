<div class="row">
    <div class="col-md-12">
        <button type="button" class="btn btn-flat btn-primary pull-right" (click)="addNewThawing()" [disabled]="!canEdit">
            <i class="fa fa-lg fa-plus"></i> Add Thaw
        </button>
    </div>
</div>
<br />
<div class="row">
    <div *ngFor="let thaw of salesOrderThawings; let i = index">
        <div class="col-md-6">
            <app-thawing-basic-info [thawing]="thaw" [canEdit]="canEdit" (onDiscardNewThaw)="discardNewThaw()"></app-thawing-basic-info>
            <br />
        </div>
    </div>
</div>
