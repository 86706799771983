import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { BaseService } from '@mt-ng2/base-service';

import { IDonorFamilyHistory } from '@model/interfaces/donor-family-history';

import { BodyStructureService} from './who-are-you-meta-items/body-structure.service';
import { EducationCompletedTypeService} from './who-are-you-meta-items/education-completed-type.service';
import { EyeColorService} from './who-are-you-meta-items/eye-color.service';
import { HairTextureService} from './who-are-you-meta-items/hair-texture.service';
import { NaturalHairColorService} from './who-are-you-meta-items/natural-hair-color.service';
import { RaceService} from './who-are-you-meta-items/race.service';
import { SkinTypeService} from './who-are-you-meta-items/skin-type.service';
import { UnitsOfMeasurementService } from './basic-info-meta-items/units-of-measurement.service';
import { IFamilyHistory } from '@model/interfaces/family-history';
import { IApplicationPartTwoValidationDTO } from '@model/interfaces/custom/application-part-two-validation-dto';
import { IFamilyHistoryDynamicControlsParameters } from '@model/form-controls/family-history.form-controls';

export const emptyFamilyHistory: IFamilyHistory = {
    HeightUnitId: 0,
    Id: 0,
    WeightUnitId: 0,
};

@Injectable({
    providedIn: 'root',
})
export class DonorFamilyHistoryService extends BaseService<IDonorFamilyHistory> {

    constructor(public http: HttpClient,
                private educationService: EducationCompletedTypeService,
                private eyeColorService: EyeColorService,
                private hairTextureService: HairTextureService,
                private hairColorService: NaturalHairColorService,
                private raceService: RaceService,
                private skinService: SkinTypeService,
                private unitsOfMesaurementService: UnitsOfMeasurementService) {
        super('/application-part-two/family-history', http);
    }

    getEmptyFamilyHistory(): IFamilyHistory {
        return { ...emptyFamilyHistory };
    }

    getFamilyHistoryAdditionalParameters(): Observable<IFamilyHistoryDynamicControlsParameters> {
        return forkJoin({
            educationCompletedTypes: this.educationService.getItems(),
            eyeColors: this.eyeColorService.getItems(),
            hairColors: this.hairColorService.getItems(),
            hairTextures: this.hairTextureService.getItems(),
            heightUnits: this.unitsOfMesaurementService.getAdultHeightItems(),
            races: this.raceService.getItems(),
            skinTypes: this.skinService.getItems(),
            weightUnits: this.unitsOfMesaurementService.getAdultWeightItems(),
        });
    }

    update(data: IDonorFamilyHistory): Observable<IApplicationPartTwoValidationDTO> {
        return super.update(data);
    }

    updateWithFks(data: IDonorFamilyHistory): Observable<IApplicationPartTwoValidationDTO> {
        return super.updateWithFks(data);
    }
}
