import { Component } from '@angular/core';
import { DonationStatuses } from '@model/enums/donation-statuses.enum';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { DateParts } from '@mt-ng2/date-module';
import { IEntityListComponentMembers, IEntityListDynamicCellComponentGeneric } from '@mt-ng2/entity-list-module';
import { MONTHS_TO_RELEASE_DATE as monthsToReleaseDate } from '../donor-donations.library';

enum DonationIcons {
    Quarantine = 1,
    EligibleForRelease,
    ApprovedForRelease,
    Discarded,
}

@Component({
    selector: 'donation-status-icon',
    template: `
        <div [ngSwitch]="IconStatus">
            <div *ngSwitchCase="DonationIcons.Quarantine">
                <i class="fa fa-circle" style="color:red; text-shadow: 0 0 3px #777777;"></i>
            </div>
            <div *ngSwitchCase="DonationIcons.EligibleForRelease">
                <i class="fa fa-circle" style="color:yellow; text-shadow: 0 0 3px #777777;"></i>
            </div>
            <div *ngSwitchCase="DonationIcons.ApprovedForRelease">
                <i class="fa fa-circle" style="color:green; text-shadow: 0 0 3px #777777;"></i>
            </div>
            <div *ngSwitchCase="DonationIcons.Discarded"><i class="fa fa-ban" style="color:red"></i></div>
        </div>
    `,
})
export class DonationStatusIconComponent implements IEntityListDynamicCellComponentGeneric<IDonorSample> {
    entity: IDonorSample;
    entityListComponentMembers: IEntityListComponentMembers;

    DonationIcons = DonationIcons;
    IconStatus: DonationIcons;
    constructor() {}

    ngOnInit(): void {
        this.getDonorIconStatus();
    }

    getDonorIconStatus(): void {
        switch (this.entity.DonationStatusId) {
            case DonationStatuses.Discard:
                this.IconStatus = DonationIcons.Discarded;
                break;
            case DonationStatuses.EligibleforRelease:
                this.IconStatus = DonationIcons.EligibleForRelease;
                break;
            case DonationStatuses.ApprovedforRelease:
                this.IconStatus = DonationIcons.ApprovedForRelease;
                break;
            case DonationStatuses.Quarantine:
            default:
                this.IconStatus = DonationIcons.Quarantine;
                break;
        }
    }
}
