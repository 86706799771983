import { convertInchesToFeetAndInches } from '@angular-common/libraries/parse-height.library';
import { Component } from '@angular/core';
import { ApplicationSections } from '@model/enums/custom/application-sections.enum';
import { UnitsOfMeasurement } from '@model/enums/units-of-measurement.enum';
import { DonorApplicationDetailsSectionBaseComponent } from '../donor-application-details-section.base.component';

@Component({
    selector: 'app-basic-info-details',
    templateUrl: './basic-info-details.component.html',
})
export class BasicInfoDetailsComponent extends DonorApplicationDetailsSectionBaseComponent {
    formId = ApplicationSections.Basicinfo;

    get adjustedHeight(): string {
        if (this.donor.DonorBasicInfo.HeightUnitId === UnitsOfMeasurement.FeetInches) {
            return convertInchesToFeetAndInches(this.donor.DonorBasicInfo.Height);
        }
        return this.donor.DonorBasicInfo.Height.toString();
    }
}
