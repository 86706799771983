import { ErrorHandler, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule } from '@mt-ng2/login-module';
import { LoginModuleConfigToken } from '@mt-ng2/login-module-config';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form-config';

import { AppRoutingModule } from './app-routing.module';
import { AdminPortalSharedModule } from '@common/shared.module';
import { AppComponent } from './app.component';
import { environment } from '@environment';
import { LoginConfigOverride } from '@common/configs/login.config';
import { DonorModule } from './admin-portal/donors/donor.module';
import { AppointmentModule } from './admin-portal/appointments/appointment.module';
import { CommonService } from '@angular-common/services/common.service';
import { RecipientUserModule } from 'admin-portal/recipient-users/recipient-user.module';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        AdminPortalSharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule.forRoot(environment),
        NotificationsModule,
        AuthModule.forRoot(),
        AppRoutingModule,
        DonorModule,
        RecipientUserModule,
        AppointmentModule,
        NgxMaskModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: DynamicFormModuleConfigToken, useValue: { commonService: CommonService } },
    ],
})
export class AppModule {}
