<ng-container *ngIf="addressControls && form">
    <div class="form-section-border">
        <h3>Address</h3>
    </div>
    <div class="input-scaffold">
        <dynamic-field [field]="basicInfoControls.Form.IsUsCitizen" [form]="form"></dynamic-field>
    </div>
    <div class="input-scaffold__group">
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.Address1" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.Address2" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.City" [form]="form"></dynamic-field>
        </div>
    </div>
    <div class="input-scaffold__group">
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.StateCode" [form]="form" [hidden]="!(showStates$ | async)">
            </dynamic-field>
            <dynamic-field [field]="addressControls.Form.Province" [form]="form" [hidden]="showStates$ | async">
            </dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.Zip" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="addressControls.Form.CountryCode" [form]="form" (controlCreated)="setShowStates($event)"></dynamic-field>
        </div>
    </div>
    <div class="input-scaffold">
        <dynamic-field [field]="addressControls.Form.IsCurrentAddress" [form]="form"
            (controlCreated)="setAddCurrentAddress($event)"></dynamic-field>
    </div>
</ng-container>

<ng-container *ngIf="currentAddressControls && form">
    <div class="form-section-border">
        <h3>Current Address</h3>
    </div>
    <div class="input-scaffold__group">
        <div class="input-scaffold">
            <dynamic-field [field]="currentAddressControls.Form.Address1" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="currentAddressControls.Form.Address2" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="currentAddressControls.Form.City" [form]="form"></dynamic-field>
        </div>
    </div>
    <div class="input-scaffold__group">
        <div class="input-scaffold">
            <dynamic-field [field]="currentAddressControls.Form.StateCode" [form]="form"
                [hidden]="!(showStatesCurrentAddress$ | async)">
            </dynamic-field>
            <dynamic-field [field]="currentAddressControls.Form.Province" [form]="form"
                [hidden]="showStatesCurrentAddress$ | async"> </dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="currentAddressControls.Form.Zip" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="currentAddressControls.Form.CountryCode" [form]="form"
                (controlCreated)="setShowStatesCurrentAddress($event)"></dynamic-field>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="basicInfoControls && form">
    <div class="form-section-border">
        <h3>Communication</h3>
    </div>
    <div class="input-scaffold__group">
        <div class="input-scaffold">
            <dynamic-field [field]="basicInfoControls.Form.ApplicantPhoneNumber" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="basicInfoControls.Form.HasTextCommunication" [form]="form"> </dynamic-field>
        </div>
    </div>

    <div class="form-section-border">
        <h3>Personal Info</h3>
    </div>
    <div class="input-scaffold">
        <label class="input-label">
            <span class="input-scaffold__label" [style.margin-bottom]="'-10px'"> Date of Birth<span
                    style="color: red">*</span> </span>
        </label>
        <dynamic-field [field]="basicInfoControls.Form.Dob" [form]="form"></dynamic-field>
    </div>

    <div class="input-scaffold" [style.margin-top]="'8px'">
        <label class="input-label">
            <span class="input-scaffold__label">Sex<span style="color: red">*</span></span>
        </label>
        <dynamic-field [field]="basicInfoControls.Form.GenderId" [form]="form"></dynamic-field>
    </div>

    <div class="input-scaffold" style="width: inherit">
        <label class="input-label">
            <span class="input-scaffold__label">Height<span style="color: red">*</span></span>
        </label>
        <dynamic-field [field]="basicInfoControls.Form.HeightUnitId" [form]="form"
            (controlCreated)="setShowImperialHeight($event)"></dynamic-field>
    </div>
    <div class="input-scaffold__group" *ngIf="showImperialHeight$ | async">
        <div class="input-scaffold">
            <dynamic-field [field]="basicInfoControls.Form.Feet" [form]="form"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="basicInfoControls.Form.Inches" [form]="form"></dynamic-field>
        </div>
    </div>
    <div class="input-scaffold" *ngIf="!(showImperialHeight$ | async)">
        <dynamic-field [field]="basicInfoControls.Form.Meters" [form]="form"></dynamic-field>
    </div>

    <div class="input-scaffold" style="width: inherit">
        <label class="input-label">
            <span class="input-scaffold__label">Weight
                <span style="color: red"> *</span>
            </span>
        </label>
        <dynamic-field [field]="basicInfoControls.Form.WeightUnitId" [form]="form"></dynamic-field>
    </div>
    <div class="input-scaffold" style="width: inherit">
        <dynamic-field [field]="basicInfoControls.Form.Weight" [form]="form"></dynamic-field>
    </div>
    <div class="form-section-border">
        <h3>Referral</h3>
    </div>
    <div class="input-scaffold">
        <dynamic-field [field]="basicInfoControls.Form.ReferralTypeId" [form]="form"
            (controlCreated)="setShowComment($event)"></dynamic-field>
    </div>
    <div class="input-scaffold" *ngIf="!(hideComments$ | async)">
        <label class="input-label">
            <span class="input-scaffold__label">{{ commentLabel }}
                <span style="color: red"> *</span>
            </span>
        </label>
        <dynamic-field [field]="basicInfoControls.Form.Comment" [form]="form"> </dynamic-field>
    </div>
</ng-container>
