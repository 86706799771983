import { DonorTestSampleLabDataSections } from '@model/enums/custom/donor-test-sample-lab-data-sections.enum';
import { TestSamplePrepMethods } from '@model/enums/test-sample-prep-methods.enum';
import { IDonorSampleLabData } from '@model/interfaces/donor-sample-lab-data';
import { IDonorSampleRemainderLabData } from '@model/interfaces/donor-sample-remainder-lab-data';

export abstract class LabDataCalculatedFieldsService {
    public static calculateTotalConcentration(currentValues?: IDonorSampleLabData): number {
        const motileConc = currentValues.RawSemenMotileConcentration ?? 0;
        const nonMotileConc = currentValues.NonMotileConcentration ?? 0;
        return motileConc + nonMotileConc;
    }

    public static calculateMotility(currentValues?: IDonorSampleLabData): number {
        const motileConc = currentValues.RawSemenMotileConcentration ?? 0;
        const totalConcentration = LabDataCalculatedFieldsService.calculateTotalConcentration(currentValues);
        return (motileConc / (totalConcentration ? totalConcentration : 1)) * 100;
    }

    public static calculateRawSemenTMCPerSample(currentValues: IDonorSampleLabData): number {
        const volume = currentValues?.RawSemenVolume ?? 0;
        const dilution = currentValues?.RawSemenDilutionFactor ?? 0;
        const motileConc = currentValues?.RawSemenMotileConcentration ?? 0;
        return volume * dilution * motileConc;
    }

    public static calculatePostGradientTMCPerSample(currentValues: IDonorSampleLabData): number {
        const volume = currentValues.PostGradientVolume ?? 0;
        const motileConc = currentValues.PostGradientMotileConcentration ?? 0;
        const dilution = currentValues.PostGradientDilutionFactor ?? 0;
        return volume * motileConc * dilution;
    }

    public static calculateRecoveryEfficiency(currentValues: IDonorSampleLabData): number {
        const rawSemenTMC = LabDataCalculatedFieldsService.calculateRawSemenTMCPerSample(currentValues);
        const postGradientTMC = LabDataCalculatedFieldsService.calculatePostGradientTMCPerSample(currentValues);
        return 100 * (postGradientTMC / (rawSemenTMC ? rawSemenTMC : 1));
    }

    public static getGuaranteeTMCValue(methodId: number): number {
        switch (methodId) {
            case TestSamplePrepMethods.IUI:
                return 10;
            case TestSamplePrepMethods.ICSI:
                return 1;
            case TestSamplePrepMethods.IVF:
                return 5;
            default:
                return 0;
        }
    }

    public static calculateFinalVolume(currentValues: IDonorSampleLabData, sectionId: number): number {
        const TMCPerSample = LabDataCalculatedFieldsService.calculatePostGradientTMCPerSample(currentValues);
        const desiredFinal = LabDataCalculatedFieldsService.calculateDesiredFinalMotileConcentration(currentValues, sectionId);
        return (TMCPerSample / (desiredFinal ? desiredFinal : 1)) * 1000;
    }

    public static calculateDesiredFinalMotileConcentration(currentValues: IDonorSampleLabData, sectionId: number): number {
        const prepMethodId = LabDataCalculatedFieldsService.isRemainderData(sectionId)
            ? currentValues.DonorTestSample?.RemainderPrepMethodId
            : currentValues.DonorTestSample?.PrepMethodId;
        if (!prepMethodId) {
            return null;
        }
        const multiplier = LabDataCalculatedFieldsService.getGuaranteeTMCValue(prepMethodId);
        return multiplier * 4.4;
    }

    public static calculateArcticAdded(currentValues: IDonorSampleLabData, sectionId: number): number {
        return 0.25 * LabDataCalculatedFieldsService.calculateFinalVolume(currentValues, sectionId);
    }

    public static calculateAddedWashMedia(currentValues: IDonorSampleLabData, sectionId: number): number {
        return LabDataCalculatedFieldsService.calculateFinalVolume(currentValues, sectionId) * 0.75 - currentValues.PostGradientVolume * 1000;
    }

    public static getVialAddVolume(): number {
        return 550;
    }

    public static calculateNumberOfVials(currentValues: IDonorSampleLabData, sectionId: number): number {
        if (currentValues.NumberOfVials) {
            return +currentValues.NumberOfVials;
        }
        const vialVolume = LabDataCalculatedFieldsService.getVialAddVolume();
        const finalVolume = LabDataCalculatedFieldsService.calculateFinalVolume(currentValues, sectionId);
        return Math.floor(finalVolume / vialVolume);
    }

    public static calculateAproxVolLeft(currentValues: IDonorSampleLabData, sectionId: number): number {
        const finalVolume = LabDataCalculatedFieldsService.calculateFinalVolume(currentValues, sectionId);
        const numberOfVials = LabDataCalculatedFieldsService.calculateNumberOfVials(currentValues, sectionId);
        const vialAddVolume = LabDataCalculatedFieldsService.getVialAddVolume();
        return finalVolume - numberOfVials * vialAddVolume;
    }

    public static calculateTMCTestVialPerSample(currentValues: IDonorSampleLabData): number {
        const vialAddVolume = LabDataCalculatedFieldsService.getVialAddVolume();
        const motileConc = currentValues.TestThawMotileConcentration ?? 0;
        return (vialAddVolume * motileConc) / 1000;
    }

    public static calculateTMCAllVials(currentValues: IDonorSampleLabData, sectionId: number): number {
        const tmcTestVialPerSample = LabDataCalculatedFieldsService.calculateTMCTestVialPerSample(currentValues);
        const numberOfVials = LabDataCalculatedFieldsService.calculateNumberOfVials(currentValues, sectionId);
        return tmcTestVialPerSample * numberOfVials;
    }

    public static calculateSurvival(currentValues: IDonorSampleLabData, sectionId: number): number {
        const tmcAllVials = LabDataCalculatedFieldsService.calculateTMCAllVials(currentValues, sectionId);
        const tmcPerSample = LabDataCalculatedFieldsService.calculatePostGradientTMCPerSample(currentValues);
        return (tmcAllVials / (tmcPerSample ? tmcPerSample : 1)) * 100;
    }

    public static calculateOverallEfficiency(currentValues: IDonorSampleLabData, sectionId: number): number {
        const tmcAllVials = LabDataCalculatedFieldsService.calculateTMCAllVials(currentValues, sectionId);
        const tmcPerSample = LabDataCalculatedFieldsService.calculateRawSemenTMCPerSample(currentValues);
        return (tmcAllVials / (tmcPerSample ? tmcPerSample : 1)) * 100;
    }

    public static isRemainderData(sectionId: number): boolean {
        return (
            sectionId === DonorTestSampleLabDataSections.RemainderTestThawInfo ||
            sectionId === DonorTestSampleLabDataSections.RemainderVialDetails ||
            sectionId === DonorTestSampleLabDataSections.RemainderVials
        );
    }

    // Remainder Calculations

    public static calculateRemainderTMCPerSample(currentValues: IDonorSampleRemainderLabData): number {
        const volume = currentValues.Volume;
        const finalMotileConcentration = LabDataCalculatedFieldsService.calculateDesiredFinalMotileConcentration(
            LabDataCalculatedFieldsService.buildSampleLabData(currentValues),
            DonorTestSampleLabDataSections.CryoPrep,
        );
        return (volume * finalMotileConcentration) / 1000;
    }

    public static calculateRemainderFinalVolume(currentValues: IDonorSampleRemainderLabData): number {
        const tmcPerSample = LabDataCalculatedFieldsService.calculateRemainderTMCPerSample(currentValues);
        const desiredFinal = LabDataCalculatedFieldsService.calculateDesiredFinalMotileConcentration(
            currentValues,
            DonorTestSampleLabDataSections.RemainderVialDetails,
        );
        return (tmcPerSample / desiredFinal) * 1000;
    }

    public static calculateRemainderArcticAdded(currentValues: IDonorSampleRemainderLabData): number {
        const finalVol = LabDataCalculatedFieldsService.calculateRemainderFinalVolume(currentValues);
        return finalVol * 0.25 - currentValues.Volume * 0.25;
    }

    public static calculateRemainderAddedWashMedia(currentValues: IDonorSampleRemainderLabData): number {
        const finalVol = LabDataCalculatedFieldsService.calculateRemainderFinalVolume(currentValues);
        return finalVol * 0.75 - currentValues.Volume * 0.75;
    }

    public static calculateRemainderNumberOfVials(currentValues: IDonorSampleRemainderLabData): number {
        const finalVol = LabDataCalculatedFieldsService.calculateRemainderFinalVolume(currentValues);
        return finalVol / 550;
    }

    public static calculateRemainderTMCAllVials(currentValues: IDonorSampleRemainderLabData): number {
        const numOfVials = LabDataCalculatedFieldsService.calculateRemainderNumberOfVials(currentValues);
        return currentValues.TestThawMotileConcentration * numOfVials;
    }

    public static calculateRemainderSurvival(currentValues: IDonorSampleRemainderLabData): number {
        const tmcAllVials = LabDataCalculatedFieldsService.calculateRemainderTMCAllVials(currentValues);
        const tmcPerSample = LabDataCalculatedFieldsService.calculateRemainderTMCPerSample(currentValues);
        return (tmcAllVials / (tmcPerSample ? tmcPerSample : 1)) * 100;
    }

    public static calculateRemainderOverallEfficiency(currentValues: IDonorSampleRemainderLabData): number {
        const donationTMCAllVials = LabDataCalculatedFieldsService.calculateTMCAllVials(
            LabDataCalculatedFieldsService.buildSampleLabData(currentValues),
            DonorTestSampleLabDataSections.TestThawInfo,
        );
        const remainderTMCAllVials = LabDataCalculatedFieldsService.calculateRemainderTMCAllVials(currentValues);
        const donationTMCPerSample = LabDataCalculatedFieldsService.calculateRawSemenTMCPerSample(currentValues.DonorTestSample.DonorSampleLabData);
        return ((donationTMCAllVials + remainderTMCAllVials) / donationTMCPerSample) * 100;
    }

    // used as a full reference to Donation Lab Data
    private static buildSampleLabData(currentValues: IDonorSampleRemainderLabData): IDonorSampleLabData {
        const DonationLabData = currentValues?.DonorTestSample?.DonorSampleLabData || { Id: 0 };
        DonationLabData.DonorTestSample = currentValues.DonorTestSample;
        return DonationLabData;
    }
}
