import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IMedicalReviewOutcome } from '@model/interfaces/medical-review-outcome';

@Injectable({
    providedIn: 'root',
})
export class MedicalReviewOutcomeService extends StaticMetaItemService<IMedicalReviewOutcome> {
    constructor(public http: HttpClient) {
        super('MedicalReviewOutcomeService', 'Outcome', 'OutcomeIds', '/meta-items/MedicalReviewOutcome', http);
    }
}
