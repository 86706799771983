import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { IMedicalIssueType } from '@model/interfaces/medical-issue-type';
import { IMetaItem } from '@model/interfaces/base';
import { IDonorMedicalHistory } from '@model/interfaces/donor-medical-history';
import { IDonorRelationshipType } from '@model/interfaces/donor-relationship-type';
import { IDonorIssue } from '@model/interfaces/donor-issue';
import { DonorIssueDynamicControlsPartial } from '@model/partials/donor-issue-partial.form-controls';
import { IDonor } from '@model/interfaces/donor';

import { MedicalIssueTypeService } from '../services/medical-issue-meta-items/medical-issue-type.service';
import { DonorRelationshipTypeService } from '../services/medical-issue-meta-items/donor-relationship-type.service';
import {DonorIssueService } from '../services/donor-issue.service';

@Component({
    selector: 'medical-issues',
    templateUrl: './medical-issues.component.html',
})
export class MedicalIssuesComponent implements OnInit, OnDestroy {
    @Input() donor: IDonor;
    @Input() isSaved: boolean;
    @Output() onFormReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
    @Output('onFormValueChanges') onFormValueChanges: EventEmitter<IDonorIssue> = new EventEmitter<IDonorIssue>();

    // abstract controls
    abstractApplicationIssueControls: any;

    medicalIssues: IDonorIssue;

    medicalIssueTypes: IMedicalIssueType[];
    donorMedicalHistory: IDonorMedicalHistory[];
    donorRelationshipType: IDonorRelationshipType[];

    selectedHasSurgeries: boolean;
    selectedIsTakingMedication: boolean;

    medicalIssuesForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    denied = false;

    subs = new Subscription();

    constructor(
        private fb: FormBuilder,
        private donorIssueService: DonorIssueService,
        private medicalIssueTypesService: MedicalIssueTypeService,
        private donorRelationshipTypeService: DonorRelationshipTypeService,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.medicalIssuesForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        forkJoin([
            this.donorIssueService.getById(this.donor.Id),
            this.donorRelationshipTypeService.getItems(),
            this.medicalIssueTypesService.getItems(),
        ]).subscribe(([donorIssue, relationshipTypes, issueTypes]) => {
            this.donorRelationshipType = relationshipTypes;
            this.medicalIssueTypes = issueTypes;
            this.medicalIssues = donorIssue;
            this.donorMedicalHistory = this.medicalIssues.DonorMedicalHistories;
            this.selectedHasSurgeries = this.medicalIssues.HasSurgeries;
            this.selectedIsTakingMedication = this.medicalIssues.IsTakingMedication;
            this.createForm();
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    createForm(): void {
        this.getControls();
        this.medicalIssuesForm = this.assignFormGroups();
        this.formCreated = true;
        if (this.denied) {
            setTimeout(() => this.medicalIssuesForm.disable());
        } else {
            this.subs.add(
                this.medicalIssuesForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
                    if (this.medicalIssuesForm.dirty) {
                        this.assignFormValues();
                    }
                }),
            );
        }
        this.onFormReady.emit(this.medicalIssuesForm);
    }

    getControls(): void {
        this.abstractApplicationIssueControls = new DonorIssueDynamicControlsPartial(this.medicalIssues, {
            formGroup: 'ApplicationIssue',
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationIssue: this.fb.group({}),
        });
    }

    assignFormValues(): void {
        const formvalues = Object.assign({}, this.medicalIssues, this.medicalIssuesForm.get('ApplicationIssue').value);
        this.onFormValueChanges.emit(formvalues);
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    medicalIssueChanged(): void {
        this.medicalIssuesForm.markAsDirty();
        this.assignFormValues();
    }

    setSubissues(e: { selectedItems: IMetaItem[]; parentIssueId: number; issueId: number }): void {
        // Set issue
        let issue = this.donorMedicalHistory.find((i) => i.MedicalIssueId === e.issueId);
        issue.Mother = e.selectedItems.some((si) => si.Name === 'Mother');
        issue.You = e.selectedItems.some((si) => si.Name === 'You');
        issue.Father = e.selectedItems.some((si) => si.Name === 'Father');
        issue.Sibling = e.selectedItems.some((si) => si.Name === 'Sibling');
        issue.Grandparent = e.selectedItems.some((si) => si.Name === 'Grandparent');
        issue.Children = e.selectedItems.some((si) => si.Name === 'Children');
        issue.None = e.selectedItems.some((si) => si.Name === 'None');
        this.medicalIssueChanged();
    }

    getIssuesByTypeId(typeId: number): IDonorMedicalHistory[] {
        return this.donorMedicalHistory.filter(
            (issue) => issue.MedicalIssue.MedicalIssueTypeId === typeId && !issue.MedicalIssue.ParentMedicalIssueId,
        );
    }

    medicalIssuesComplete(): boolean {
        return this.donorMedicalHistory.every((issue) => {
            if (issue.MedicalIssue.ParentMedicalIssueId) {
                const parentIssue = this.donorMedicalHistory.find((mi) => mi.MedicalIssueId === issue.MedicalIssue.ParentMedicalIssueId);
                if (this.donorRelationshipType.some((type) => parentIssue[type.Name] === true && type.Name !== 'None')) {
                    return this.donorRelationshipType.some((type) => issue[type.Name]);
                } else {
                    return true;
                }
            } else {
                return this.donorRelationshipType.some((type) => {
                    return issue[type.Name];
                });
            }
        });
    }
}
