import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IDonorDynamicControlsParameters } from '@model/form-controls/donor.form-controls';
import { DonorDynamicControlsPartial } from '@model/partials/donor-partial.form-controls';
import { IDonor } from '@model/interfaces/donor';
import { DonorDenialTypes } from '@model/enums/donor-denial-types.enum';

export class DonorDynamicConfig<T extends IDonor> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private donor: T, additionalParams: IDonorDynamicControlsParameters, private configControls?: string[]) {
        super();

        const dynamicControls = new DonorDynamicControlsPartial(this.donor, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = [
                'Id',
                'Active',
                'IsTemporarilyInactive',
                'PermanentlyInactive',
                'Published',
                'DonorStatusId',
                'MedicalReviewId',
                'DonorFee',
                'CmvStatusId',
                'OverrideScreeningWorkflow',
                'Iui',
                'Ivf',
                'Icsi',
                'ComingSoon',
                'AvailableOutsideUs',
            ];
            if (this.donor.DenialReasonId === DonorDenialTypes.Other) {
                this.configControls.push('ManualDenialReason');
            }
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
