<div *ngIf="recipientUser">
    <div class="row">
        <div class="col-md-12">
            <button type="button" class="btn btn-primary" (click)="showEmailModal()">Send Welcome Email</button>
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-md-6">
            <app-recipient-user-basic-info [recipientUser]="recipientUser" [canEdit]="canEdit"></app-recipient-user-basic-info>
            <br />
            <app-recipient-reservations [recipientId]="recipientUser.Id"></app-recipient-reservations>
            <br />
        </div>
        <div class="col-md-6">
            <div *ngIf="recipientUser.Recipient">
                <app-common-addresses
                    [canEdit]="canEdit"
                    [address]="recipientUser.Recipient.Address"
                    (onSave)="saveAddress($event)"
                    (onDelete)="deleteAddress($event)"
                    [allowInternationalAddresses]="true"
                ></app-common-addresses>
                <br />
            </div>
            <app-notes [entityType]="noteEntity" [entityId]="recipientUser.Id" [canEdit]="canEdit"></app-notes>
            <br />
            <app-upload-documents-detail [entityId]="recipientUser.Id" [claimType]="claimType" [entityType]="docEntityType">
            </app-upload-documents-detail>
            <br />
            <app-sales-orders [recipientId]="recipientUser.Id"></app-sales-orders>
            <br />
        </div>
    </div>
    <a routerLink="/recipient-users" class="btn btn-default">Close</a>
</div>

<send-email-template (ready)="emailModalApi = $event"></send-email-template>
