import { NgModule } from '@angular/core';

import { DonorRoutingModule } from './donor-routing.module';
import { DonorBasicInfoComponent } from './donor-basic-info/donor-basic-info.component';
import { DonorDetailComponent } from './donor-detail/donor-detail.component';
import { DonorsComponent } from './donor-list/donors.component';
import { DonorHeaderComponent } from './donor-header/donor-header.component';
import { AdminPortalFeatureModule } from '@common/feature.module';
import { AdminPortalSharedModule } from '@common/shared.module';
import { DonorPsychologicalScreeningComponent } from './initial-screening/donor-psychological-screening/donor-psychological-screening.component';
import { GeneticScreeningComponent } from './initial-screening/genetic-screening/genetic-screening.component';
import { BloodWorkComponent } from './initial-screening/blood-work/blood-work.component';
import { DonorTestSamplesComponent } from './initial-screening/donor-test-samples/donor-test-samples.component';
import { DonorTestSampleBasicInfoComponent } from './initial-screening/donor-test-samples/donor-test-sample-basic-info/donor-test-sample-basic-info.component';
import { DonorTestSampleDetailComponent } from './initial-screening/donor-test-samples/donor-test-sample-detail/donor-test-sample-detail.component';
import { AngularCommonModule } from '@angular-common/angular-common.module';
import { DonorApplicationDetailsComponent } from './donor-application-details/donor-application-details.component';
import { DonorApplicationDetailsSectionBaseComponent } from './donor-application-details/sections/donor-application-details-section.base.component';
import { BasicInfoDetailsComponent } from './donor-application-details/sections/basic-info/basic-info-details.component';
import { PreliminaryHistoryDetailsComponent } from './donor-application-details/sections/prelim-history/prelim-history-details.component';
import { MedicalIssuesDetailsComponent } from './donor-application-details/sections/medical-issues/medical-issues-details.component';
import { DonorScoreComponent } from './donor-score/donor-score.component';
import { DonorScoreMedicalIssuesComponent } from './donor-score/donor-score-medical-issue/donor-score-medical-issue.component';
import { DonorMedicalIssuesComponent } from './donor-application-details/sections/medical-issues/donor-medical-issue/donor-medical-issue.component';
import { WhoAreYouDetailsComponent } from './donor-application-details/sections/who-are-you/who-are-you-details.component';
import { FamilyHistoryDetailsComponent } from './donor-application-details/sections/family-history/family-history-details.component';
import { IndividualFamilyHistoryDetailComponent } from './donor-application-details/sections/family-history/individual-family-history/individual-family-history.component';
import { DonorAddressesDetailsComponent } from './donor-application-details/sections/basic-info/donor-addresses-details.component';
import { DonorInitialScreeningComponent } from './initial-screening/donor-initial-screening.component';
import { DonorEmailComponent } from './donor-emails/donor-email.component';
import { DonorPhotoManagementComponent } from './donor-photo-management/donor-photo-management.component';
import { DonorPhotoUploaderComponent } from './donor-photo-management/donor-photo-uploader.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HelloSignDocumentsComponent } from './hello-sign-docs/hello-sign-docs.component';
import { DonorSampleModule } from './donor-donations/donor-sample.module';
import { DonorOngoingScreeningModule } from './donor-ongoing-screenings/donor-ongoing-screening.module';
import { CommonComponentsModule } from '@angular-common/components/components.module';
import { DonorSampleLabDataModule } from './donor-sample-lab-data/donor-sample-lab-data.module';
import { DonorInventoryCardComponent } from './donor-inventory-card/donor-inventory-card.component';
import { VialTypeCountsPipe } from './donor-inventory-card/display-vial-count.pipe';
import { SalesOrderCellComponent } from './donor-inventory-card/sales-order-cell.component';
import { PotentialFamiliesComponent } from './donor-potential-families/donor-potential-families.component';

@NgModule({
    declarations: [
        DonorsComponent,
        DonorHeaderComponent,
        DonorDetailComponent,
        DonorBasicInfoComponent,
        DonorPsychologicalScreeningComponent,
        GeneticScreeningComponent,
        BloodWorkComponent,
        DonorTestSamplesComponent,
        DonorTestSampleBasicInfoComponent,
        DonorTestSampleDetailComponent,
        DonorScoreComponent,
        DonorScoreMedicalIssuesComponent,
        DonorApplicationDetailsComponent,
        DonorApplicationDetailsSectionBaseComponent,
        BasicInfoDetailsComponent,
        DonorScoreComponent,
        DonorScoreMedicalIssuesComponent,
        PreliminaryHistoryDetailsComponent,
        MedicalIssuesDetailsComponent,
        DonorMedicalIssuesComponent,
        WhoAreYouDetailsComponent,
        FamilyHistoryDetailsComponent,
        IndividualFamilyHistoryDetailComponent,
        DonorAddressesDetailsComponent,
        DonorInitialScreeningComponent,
        DonorEmailComponent,
        DonorPhotoManagementComponent,
        DonorPhotoUploaderComponent,
        HelloSignDocumentsComponent,
        DonorInventoryCardComponent,
        VialTypeCountsPipe,
        SalesOrderCellComponent,
        PotentialFamiliesComponent,
    ],
    imports: [
        AdminPortalFeatureModule,
        AdminPortalSharedModule,
        DonorRoutingModule,
        AngularCommonModule,
        DragDropModule,
        DonorSampleModule,
        DonorOngoingScreeningModule,
        CommonComponentsModule,
        DonorSampleLabDataModule,
    ],
})
export class DonorModule {}
