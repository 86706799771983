import { NgModule } from '@angular/core';

import { KeyboardShortcutService } from 'ngx-keyboard-shortcuts';

import { KeyboardShortcutModule } from '@mt-ng2/keyboard-shortcuts-module';
import { AuditLoggingModuleConfigToken, AuditLoggingModuleUserServiceToken } from '@mt-ng2/audit-logging-module-config';
import { IEntityListModuleConfig, EntityListModuleConfigToken } from '@mt-ng2/entity-list-module-config';

import { SharedModule } from '@angular-common/shared.module';

import { auditLogModuleConfig } from './configs/audit-log.config';
import { AuditLogUserService } from './services/audit-log-user.service';
import { AuditLogModule } from '@mt-ng2/audit-logging-module';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    exports: [SharedModule, KeyboardShortcutModule, AuditLogModule],
    imports: [SharedModule, KeyboardShortcutModule, AuditLogModule],
})
export class AdminPortalSharedModule {
    static forRoot(): any {
        return {
            ngModule: AdminPortalSharedModule,
            providers: [
                KeyboardShortcutService,
                { provide: EntityListModuleConfigToken, useValue: entityListModuleConfig },
                { provide: AuditLoggingModuleConfigToken, useValue: auditLogModuleConfig },
                { provide: AuditLoggingModuleUserServiceToken, useExisting: AuditLogUserService },
            ],
        };
    }
}
