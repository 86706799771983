import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IInventory } from '../interfaces/inventory';
import { IReservation } from '../interfaces/reservation';
import { ISalesOrder } from '../interfaces/sales-order';
import { ISamplePrepMethod } from '../interfaces/sample-prep-method';

export interface IInventoryDynamicControlsParameters {
    formGroup?: string;
    samplePrepMethods?: ISamplePrepMethod[];
    reservations?: IReservation[];
    salesOrders?: ISalesOrder[];
}

export class InventoryDynamicControls {

    formGroup: string;
    samplePrepMethods: ISamplePrepMethod[];
    reservations: IReservation[];
    salesOrders: ISalesOrder[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private inventory?: IInventory, additionalParameters?: IInventoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Inventory';
        this.samplePrepMethods = additionalParameters && additionalParameters.samplePrepMethods || undefined;
        this.reservations = additionalParameters && additionalParameters.reservations || undefined;
        this.salesOrders = additionalParameters && additionalParameters.salesOrders || undefined;

        this.Form = {
            CaneId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cane',
                name: 'CaneId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.CaneId || null,
            }),
            Comments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comments',
                name: 'Comments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.inventory && this.inventory.hasOwnProperty('Comments') && this.inventory.Comments !== null ? this.inventory.Comments.toString() : '',
            }),
            Discarded: new DynamicField({
                formGroup: this.formGroup,
                label: 'Discarded',
                name: 'Discarded',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventory && this.inventory.hasOwnProperty('Discarded') && this.inventory.Discarded !== null ? this.inventory.Discarded : false,
            }),
            DonationSampleId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donation Sample',
                name: 'DonationSampleId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.DonationSampleId || null,
            }),
            ReservationId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reservation',
                name: 'ReservationId',
                options: this.reservations,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventory && this.inventory.ReservationId || null,
            }),
            ReserveForResearch: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reserve For Research',
                name: 'ReserveForResearch',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventory && this.inventory.hasOwnProperty('ReserveForResearch') && this.inventory.ReserveForResearch !== null ? this.inventory.ReserveForResearch : false,
            }),
            SalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sales Order',
                name: 'SalesOrderId',
                options: this.salesOrders,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventory && this.inventory.SalesOrderId || null,
            }),
            SamplePrepMethodId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Sample Prep Method',
                name: 'SamplePrepMethodId',
                options: this.samplePrepMethods,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.SamplePrepMethodId || null,
            }),
            Unsellable: new DynamicField({
                formGroup: this.formGroup,
                label: 'Unsellable',
                name: 'Unsellable',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.inventory && this.inventory.hasOwnProperty('Unsellable') && this.inventory.Unsellable !== null ? this.inventory.Unsellable : false,
            }),
            VialNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Vial Number',
                name: 'VialNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.inventory && this.inventory.VialNumber || null,
            }),
        };

        this.View = {
            CaneId: new DynamicLabel(
                'Cane',
                this.inventory && this.inventory.CaneId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            Comments: new DynamicLabel(
                'Comments',
                this.inventory && this.inventory.hasOwnProperty('Comments') && this.inventory.Comments !== null ? this.inventory.Comments.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            Discarded: new DynamicLabel(
                'Discarded',
                this.inventory && this.inventory.hasOwnProperty('Discarded') && this.inventory.Discarded !== null ? this.inventory.Discarded : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonationSampleId: new DynamicLabel(
                'Donation Sample',
                this.inventory && this.inventory.DonationSampleId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            ReservationId: new DynamicLabel(
                'Reservation',
                getMetaItemValue(this.reservations, this.inventory && this.inventory.hasOwnProperty('ReservationId') && this.inventory.ReservationId !== null ? this.inventory.ReservationId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ReserveForResearch: new DynamicLabel(
                'Reserve For Research',
                this.inventory && this.inventory.hasOwnProperty('ReserveForResearch') && this.inventory.ReserveForResearch !== null ? this.inventory.ReserveForResearch : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            SalesOrderId: new DynamicLabel(
                'Sales Order',
                getMetaItemValue(this.salesOrders, this.inventory && this.inventory.hasOwnProperty('SalesOrderId') && this.inventory.SalesOrderId !== null ? this.inventory.SalesOrderId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            SamplePrepMethodId: new DynamicLabel(
                'Sample Prep Method',
                getMetaItemValue(this.samplePrepMethods, this.inventory && this.inventory.hasOwnProperty('SamplePrepMethodId') && this.inventory.SamplePrepMethodId !== null ? this.inventory.SamplePrepMethodId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Unsellable: new DynamicLabel(
                'Unsellable',
                this.inventory && this.inventory.hasOwnProperty('Unsellable') && this.inventory.Unsellable !== null ? this.inventory.Unsellable : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            VialNumber: new DynamicLabel(
                'Vial Number',
                this.inventory && this.inventory.VialNumber || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
        };

    }
}
