import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseService, IMetaItem } from '@mt-ng2/base-service';
import { IUser } from '@model/interfaces/user';
import { IPhone } from '@model/interfaces/base';
import { ICreateUserPayload } from '@model/interfaces/custom/create-user-payload';
import { IAddress } from '@mt-ng2/dynamic-form';
import { ImageTypes } from '@model/enums/custom/image-types.enum';
import { ClaimTypes } from '@model/ClaimTypes';
import { UserRoles } from '@model/enums/user-roles.enum';

@Injectable({
    providedIn: 'root',
})
export class UserService extends BaseService<IUser> {
    private emptyUser: IUser = {
        AuthUserId: 0,
        DateCreated: new Date(),
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        Version: null,
    };

    constructor(public http: HttpClient) {
        super('/users', http);
    }

    getEmptyUser(): IUser {
        return { ...this.emptyUser };
    }

    createUser(data: ICreateUserPayload): Observable<number> {
        return this.http.post<number>(`/users/create`, data);
    }

    saveAddress(userId: number, address: IAddress): Observable<number> {
        if (!address.Id) {
            address.Id = 0;
            return this.http.post<number>(`/users/${userId}/address`, address);
        } else {
            return this.http.put<number>(`/users/${userId}/address`, address);
        }
    }

    deleteAddress(userId: number): Observable<object> {
        return this.http.delete('/users/' + userId + '/address', {
            responseType: 'text' as 'json',
        });
    }

    savePhones(userId: number, phones: IPhone): any {
        return this.http.put<number>(`/users/${userId}/phones`, phones);
    }

    savePhoto(userId: number, photo: File): any {
        let formData: FormData = new FormData();
        formData.append('file', photo, photo.name);

        return this.http.post(`/users/${userId}/pic`, formData);
    }

    deletePhoto(userId: number): any {
        return this.http.delete(`/users/${userId}/pic`);
    }

    getUsersByRoleAsMetaItem(roleId: UserRoles = 0): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>(`/users/role/${roleId}`);
    }

    getUsersByClaimAsMetaItem(claimId: ClaimTypes = 0): Observable<IMetaItem[]> {
        return this.http.get<IMetaItem[]>(`/users/claim/${claimId}`);
    }

    getImageApiPath(userId: number, type: ImageTypes): string {
        return `/users/${userId}/pic/${type}`;
    }
}
