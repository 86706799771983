import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection, EntityListExportConfig } from '@mt-ng2/entity-list-module';

import { DonorService } from '../services/donor.service';
import { DonorStatusService } from '../services/donor-status.service';
import { IDonor } from '@model/interfaces/donor';
import { entityListModuleConfig } from '@common/shared.module';
import { DonorsEntityListConfig } from './donors.entity-list-config';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control-config';
import { ExtraParams } from '@common/extra-params/extra-params.library';
import { SearchFilterItems } from '@common/search-filter-items/search-filter-items.library';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-donors',
    templateUrl: './donors.component.html',
})
export class DonorsComponent implements OnInit {
    donors: IDonor[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    donorStatuses: MtSearchFilterItem[] = [];
    entityListConfig = new DonorsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    donorCreationStartDate: Date;
    donorCreationEndDate: Date;
    includeInactive = false;
    overrideScreeningWorkflow = false;

    constructor(
        private donorService: DonorService,
        private donorStatusService: DonorStatusService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.donorStatusService.getSearchFilterItems().subscribe((answer) => (this.donorStatuses = answer));
        this.entityListConfig.export = new EntityListExportConfig({
            exportName: 'Donors List',
            getDataForExport: this.getDonorsForExport.bind(this),
        });
        this.getDonors();
    }

    getDonorsForExport(): any {
        return this.getDonorsCall({ forExport: true }).pipe(map((answer: any) => answer.body));
    }

    getDonorsCall(options = { forExport: false }): Observable<HttpResponse<IDonor[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = ExtraParams.build({
            DateCreatedFrom: this.donorCreationStartDate,
            DateCreatedTo: this.donorCreationEndDate,
            DonorStatusIds: SearchFilterItems.getSelectedIds(this.donorStatuses),
            IncludeInactive: this.includeInactive.toString(),
            OverrideScreeningWorkflow: this.overrideScreeningWorkflow,
        });

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: options.forExport ? 0 : (this.currentPage - 1) * this.itemsPerPage,
            take: options.forExport ? this.total : this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.donorService.get(searchparams);
    }

    getDonors(): void {
        this.getDonorsCall().subscribe((answer) => {
            this.donors = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getDonors();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getDonors();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDonors();
    }

    donorSelected(event: IItemSelectedEvent): void {
        this.router.navigate(['/donors', event.entity.Id]);
    }

    dateSelectionChanged(value: ISearchFilterDaterangeValue): void {
        this.donorCreationStartDate = value.startDate;
        this.donorCreationEndDate = value.endDate;
        this.filterSelectionChanged();
    }

    toggleInactiveApplications(event: any): void {
        this.includeInactive = !this.includeInactive;
        this.getDonors();
    }

    toggleScreeningWorkflow(event: any): void {
        this.overrideScreeningWorkflow = !this.overrideScreeningWorkflow;
        this.getDonors();
    }
}
