import { IDonor } from '@model/interfaces/donor';
import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { AddressTypes } from '@model/enums/custom/address-types.enum';

export class DonorsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Donor Id',
                }),
                new EntityListColumn({
                    accessors: ['DonorUser', 'FirstName'],
                    name: 'First Name',
                }),
                new EntityListColumn({
                    accessors: ['DonorUser', 'LastName'],
                    name: 'Last Name',
                }),
                new EntityListColumn({
                    accessors: ['DonorStatus', 'Name'],
                    name: 'Donor Status',
                }),
                new EntityListColumn({
                    accessors: ['DonorUser', 'Email'],
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessorFunction: (donor: IDonor) => {
                        const addresses = donor.DonorBasicInfo.DonorAddresses;
                        if (addresses.length) {
                            const current = addresses.find((da) => da.AddressTypeId === AddressTypes.Current);
                            if (current) {
                                return current;
                            } else {
                                return addresses.find((da) => da.AddressTypeId === AddressTypes.Primary);
                            }
                        } else {
                            return null;
                        }
                    },
                    name: 'Address',
                    pipes: ['address'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DonorBasicInfo', 'ApplicantPhoneNumber'],
                    name: 'Phone',
                    pipes: ['phone'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DateCreated'],
                    name: 'Application Date Created',
                    pipes: ['date:mediumDate'],
                }),
            ],
            rowClass: (entity: IDonor) => (entity.AccountInactive ? 'archived' : null),
        };
        super(listConfig);
    }
}
