<div *ngIf="donor">
    <div class="row">
        <div class="col-md-6">
            <app-donor-basic-info [donor]="donor" [canEdit]="canEdit"></app-donor-basic-info>
            <br />
            <app-donor-score [donor]="donor"></app-donor-score>
            <br />
            <app-donor-application-details [donor]="donor" [canEdit]="canEdit"></app-donor-application-details>
            <br />
            <app-donor-inventory [donorId]="donor.Id"></app-donor-inventory>
            <br />
            <app-potential-families [donorId]="donor.Id"></app-potential-families>
            <br />
            <app-hello-sign-documents (onRequestSent)="getDonorById(donor.Id)" [donor]="donor"></app-hello-sign-documents>
            <br />
            <app-donor-emails [user]="donor.DonorUser" [donorId]="donor.Id"></app-donor-emails>
            <br />
        </div>
        <div class="col-md-6">
            <app-notes [entityType]="noteEntity" [entityId]="donor.Id" [canEdit]="canEdit"></app-notes>
            <br />
            <app-upload-documents-detail [entityId]="donor.Id" [claimType]="claimType" [entityType]="docEntityType"> </app-upload-documents-detail>
            <br />
            <app-donor-initial-screening [donor]="donor" [canEdit]="canEdit"></app-donor-initial-screening>
            <br />
            <app-donor-photos [donor]="donor" [canEdit]="canEdit"></app-donor-photos>
            <br />
            <app-donor-donations [donorId]="donor.Id" (samplesUpdated)="getDonationDates($event)"></app-donor-donations>
            <br />
            <app-donor-ongoing-screenings [donor]="donor" [canEdit]="canEdit"></app-donor-ongoing-screenings>
            <br />
        </div>
    </div>
    <a routerLink="/donors" class="btn btn-default">Close</a>
</div>
