<ng-container>
    <!-- View Items -->
    <div *ngIf="!isEditing">
        <h4>
            Genetic Screening Info
            <span class="pull-right" *ngIf="canUpload">
                <i class="fa fa-lg fa-plus" (click)="edit(); showUploadArea = true"></i>
                <i class="fa fa-lg fa-edit" (click)="edit()"></i>
            </span>
        </h4>
        <div *ngIf="documentArray?.length" class="indented">
            <div class="row">
                <div class="col-md-12">
                    <div *ngFor="let geneticScreeningDoc of documentArray; let i = index">
                        <ng-container *ngIf="geneticScreeningDoc.GeneticScreenings.length">
                            <h4>
                                {{
                                    geneticScreeningDoc.GeneticScreenings[0].GeneticScreeningTypeId === GeneticScreeningTypes.CarrierTesting
                                        ? 'Genetic Testing Labs'
                                        : geneticScreeningDoc.GeneticScreenings[0].GeneticScreeningTypeId === GeneticScreeningTypes.GeneticConsult
                                        ? 'Genetic Consult/Screening'
                                        : 'Genetic Screening'
                                }}: {{ geneticScreeningDoc.Document ? geneticScreeningDoc.Document.Name : 'No File Available' }}
                            </h4>
                            <table class="readonly-table" style="width: 100%">
                                <!--Genetic Consult/Screening-->
                                <div *ngIf="geneticScreeningDoc.GeneticScreenings[0].GeneticScreeningTypeId === GeneticScreeningTypes.GeneticConsult">
                                    <tr>
                                        <th>Genetic Consult Info Sent to Counselor Date:</th>
                                        <td>
                                            {{
                                                geneticScreeningDoc.GeneticScreenings[0].GeneticConsultSentToCounselorDate
                                                    | localize
                                                    | date: 'shortDate'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Consult Performed Date:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0].GeneticConsultPerformedDate | localize | date: 'shortDate' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Consult Report Received Date:</th>
                                        <td>
                                            {{
                                                geneticScreeningDoc.GeneticScreenings[0].GeneticConsultReportReceivedDate
                                                    | localize
                                                    | date: 'shortDate'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Consult Report Received By:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0].GeneticConsultReportReceivedBy }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Consult Accepted / Rejected:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0].GeneticConsultAcceptedRejected ? 'Accepted' : 'Rejected' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Consult Accepted / Rejected Date:</th>
                                        <td>
                                            {{
                                                geneticScreeningDoc.GeneticScreenings[0].GeneticConsultAcceptedRejectedDate
                                                    | localize
                                                    | date: 'shortDate'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Consult Accepted / Rejected By:</th>
                                        <td>{{ geneticScreeningDoc.GeneticScreenings[0].GeneticConsultAcceptedRejectedBy }}</td>
                                    </tr>
                                </div>
                                <!--Genetic Testing Labs-->
                                <div *ngIf="geneticScreeningDoc.GeneticScreenings[0].GeneticScreeningTypeId === GeneticScreeningTypes.CarrierTesting">
                                    <tr>
                                        <th>Carrier Testing Draw Date:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0].CarrierTestingDrawDate | localize | date: 'shortDate' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Carrier Testing Results Received Date:</th>
                                        <td>
                                            {{
                                                geneticScreeningDoc.GeneticScreenings[0].CarrierTestingResultsReceivedDate
                                                    | localize
                                                    | date: 'shortDate'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Carrier Testing Results Received By:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0].CarrierTestingResultsReceivedBy }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Carrier Testing Accepted/Rejected:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0].CarrierTestingAcceptedRejected ? 'Accepted' : 'Rejected' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Carrier Testing Accepted/Rejected Date:</th>
                                        <td>
                                            {{
                                                geneticScreeningDoc.GeneticScreenings[0].CarrierTestingAcceptedRejectedDate
                                                    | localize
                                                    | date: 'shortDate'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Carrier Testing Accepted/Rejected By:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0].CarrierTestingAcceptedRejectedBy }}
                                        </td>
                                    </tr>
                                </div>
                                <!--Legacy Records-->
                                <div *ngIf="!geneticScreeningDoc.GeneticScreenings[0].GeneticScreeningTypeId">
                                    <tr>
                                        <th>Genetic Screening Accepted / testRejected:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticScreeningAccepted ? 'Accepted' : 'Rejected' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Screening Accepted / Rejected By:</th>
                                        <td>{{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticScreeningAcceptedRejectedBy }}</td>
                                    </tr>
                                    <tr>
                                        <th>Genetic Screening Accepted / Rejected Date:</th>
                                        <td>
                                            {{
                                                geneticScreeningDoc.GeneticScreenings[0]?.GeneticScreeningAcceptedRejectedDate
                                                    | localize
                                                    | date: 'shortDate'
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetics Labs Accepted / Rejected:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticsLabsAccepted ? 'Accepted' : 'Rejected' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetics Labs Approved By:</th>
                                        <td>{{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticsLabsApprovedBy }}</td>
                                    </tr>
                                    <tr>
                                        <th>Genetics Labs Approved Date:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticsLabsApprovedDate | localize | date: 'shortDate' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetics Labs Performed By Date:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticsLabsPerformedByDate | localize | date: 'shortDate' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetics Labs Received Date:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticsLabsReceivedDate | localize | date: 'shortDate' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Genetics Labs Sent Date:</th>
                                        <td>
                                            {{ geneticScreeningDoc.GeneticScreenings[0]?.GeneticsLabsSentDate | localize | date: 'shortDate' }}
                                        </td>
                                    </tr>
                                </div>
                            </table>
                            <hr *ngIf="i !== documentArray.length - 1" />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <em *ngIf="!documentArray?.length">No Documents</em>
    </div>

    <!-- Edit Items -->
    <div *ngIf="isEditing && !showUploadArea">
        <h4>
            Edit Genetic Screening Info
            <span class="pull-right">
                <i class="fa fa-lg fa-plus" (click)="showUploadArea = true"></i>
            </span>
        </h4>
        <div class="table-responsive">
            <table class="table scrollable-table">
                <thead>
                    <tr class="no-pointer">
                        <th>
                            <b>Name</b>
                        </th>
                        <th>
                            <b>Date Uploaded</b>
                        </th>
                        <th>
                            <b>Document Type</b>
                        </th>
                        <th>
                            <b>Edit</b>
                        </th>
                        <th>
                            <b>Delete</b>
                        </th>
                        <th>
                            <b>Download</b>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let doc of documentArray; let i = index" class="no-pointer">
                        <td *ngIf="doc">
                            <span> {{ doc.Document ? doc.Document.Name : 'N/A' }}</span>
                        </td>
                        <td *ngIf="doc">
                            <span> {{ doc.Document ? (doc.Document.DateUpload | date: 'shortDate') : 'N/A' }}</span>
                        </td>
                        <td *ngIf="doc">
                            <span> {{ doc.DocumentType.Name }}</span>
                        </td>
                        <td class="text-center">
                            <button
                                type="button"
                                title="Edit Genetic Screening"
                                (click)="editGeneticScreening(doc)"
                                class="btn btn-sm fa fa-edit fa-lg pointer"
                            ></button>
                        </td>
                        <td class="text-center">
                            <button
                                type="button"
                                (mtConfirm)="deleteGeneticScreeningDocument(doc)"
                                class="btn btn-sm fa fa-trash fa-lg pointer"
                            ></button>
                        </td>
                        <td class="text-center">
                            <button
                                *ngIf="doc.Document"
                                type="button"
                                (click)="downloadDocument(i, doc.DocumentId)"
                                class="btn btn-sm fa fa-download fa-lg pointer"
                            ></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
        </div>
    </div>

    <!-- Upload Area -->
    <div [hidden]="!showUploadArea">
        <h4>Add Genetic Screening</h4>
        <div>
            <button type="button" class="btn btn-flat btn-primary" (click)="selectType(GeneticScreeningTypes.CarrierTesting)">
                Genetic Testing Labs
            </button>
            <button type="button" class="btn btn-flat btn-primary" (click)="selectType(GeneticScreeningTypes.GeneticConsult)">
                Genetic Consult/Screening
            </button>
        </div>
        <br />
        <button type="button" class="btn btn-flat btn-default" (click)="showUploadArea = false">Cancel</button>
    </div>
</ng-container>
<!-- preview modal -->
<modal-wrapper *ngIf="showModal" [options]="modalOptions" (cancelClick)="closePreview()">
    <div *ngIf="!isUploading">
        <h2>
            {{
                geneticScreeningType === GeneticScreeningTypes.CarrierTesting
                    ? 'Genetic Testing Labs'
                    : geneticScreeningType === GeneticScreeningTypes.GeneticConsult
                    ? 'Genetic Consult/Screening'
                    : 'Genetic Screening Info'
            }}
        </h2>
        <p><strong>Name:</strong> {{ this.tempFile ? this.tempFile.file.name : currentlyEditedFileName ? currentlyEditedFileName : 'N/A' }}</p>
        <br />
        <hr />
        <app-dynamic-form *ngIf="config" [config]="config" [form]="form"> </app-dynamic-form>
        <mt-document
            [allowedMimeType]="allowedDocumentTypes"
            [maxFileSize]="maxFileSize"
            (onAfterAddingFile)="confirm($event)"
            (onAddingFileFailed)="error($event)"
        ></mt-document>
        <br />
        <button *ngIf="documentTypeSelected" type="button" class="btn btn-success" (click)="save()">Save Document</button>
        <button type="button" class="btn btn-default" (click)="closePreview()">Discard</button>
    </div>

    <div *ngIf="isUploading">
        <h2>Your File Is Being Uploaded</h2>
    </div>
</modal-wrapper>
