import { IUser } from '@model/interfaces/user';
import { IDonorUser } from '@model/interfaces/donor-user';
import { ActivatedRoute } from '@angular/router';

function getUserFullName(user: IUser): string {
    let fullname = '';
    fullname += user.FirstName ? user.FirstName : '';
    fullname += user.LastName ? ' ' + user.LastName : '';
    return fullname;
}

function getDonorFullName(user: IDonorUser): string {
    let fullname = '';
    fullname += user.FirstName ? user.FirstName : '';
    fullname += user.MiddleInitial.trim() ? ' ' + user.MiddleInitial + '. ' : '';
    fullname += user.LastName ? ' ' + user.LastName : '';
    return fullname;
}

function getIdFromRoute(route: ActivatedRoute, param: string): number {
    let id = route.snapshot.paramMap.get(param);
    return parseInt(id, 10) === NaN ? null : parseInt(id, 10);
}

export { getUserFullName, getDonorFullName, getIdFromRoute };
