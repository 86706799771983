import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IDonorMedicalHistory } from '@model/interfaces/donor-medical-history';
import { IDonorRelationshipType } from '@model/interfaces/donor-relationship-type';
import { IMedicalIssue } from '@model/interfaces/medical-issue';

import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { Subscription } from 'rxjs';

@Component({
    selector: 'issue-selector',
    templateUrl: './issue-selector.component.html',
})
export class IssueSelectorComponent implements OnInit {
    @Input() donorMedicalHistory: IDonorMedicalHistory;
    @Input() donorRelationshipTypes: IDonorRelationshipType[];
    @Input() denied: boolean;
    @Input() isSaved: boolean;
    @Output('valueChange') valueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output('subissueChange') subissueChange: EventEmitter<any> = new EventEmitter<any>();

    medicalIssue: IMedicalIssue;
    relationshipTypes: MultiselectItem[];
    subissues: IMedicalIssue[];
    subissueRelationshipTypes: MultiselectItem[][] = [[], []];

    subs = new Subscription();

    get anyIssuesSelected(): boolean {
        return this.relationshipTypes && this.relationshipTypes.some((t) => t.Item.Name !== 'None' && t.Selected);
    }

    get medicalIssueName(): string {
        return this.medicalIssue && this.medicalIssue.Name ? this.medicalIssue.Name : '';
    }

    get isAnswered(): boolean {
        return this.relationshipTypes.some((item) => item.Selected);
    }

    get isSubissue1Answered(): boolean {
        return this.subissueRelationshipTypes[0].some((item) => item.Selected);
    }

    get isSubissue2Answered(): boolean {
        return this.subissueRelationshipTypes[1].some((item) => item.Selected);
    }

    constructor(
        ) {}

    ngOnInit(): void {
        this.medicalIssue = this.donorMedicalHistory.MedicalIssue;
        this.relationshipTypes = this.donorRelationshipTypes.map((type) => new MultiselectItem(type, this.itemSelected(type)));
        this.subissues = this.medicalIssue.MedicalIssues;
        if (this.subissues && this.subissues.length) {
            this.subissueRelationshipTypes[0].push(
                ...this.donorRelationshipTypes.map((type) => new MultiselectItem(type, this.subissueSelected(type, 0))),
            );
            if (this.subissues[1]) {
                this.subissueRelationshipTypes[1].push(
                    ...this.donorRelationshipTypes.map((type) => new MultiselectItem(type, this.subissueSelected(type, 1))),
                );
            }
            this.filterSubissueOptions();
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    selectionChanged(evt): void {
        if (evt.selectedItems.some((item) => item.Name === 'None') && !this.donorMedicalHistory.None) {
            this.relationshipTypes.forEach((item) => (item.Selected = item.Item.Name === 'None'));
            this.assignSelectedValues();
            // Clear subissues
            if (this.subissues && this.subissues.length) {
                const mockEmptySelectedItems = { selectedItems: [] };
                this.subissueChanged(mockEmptySelectedItems, 0);
                if (this.subissues[1]) {
                    this.subissueChanged(mockEmptySelectedItems, 1);
                }
            }
        } else {
            this.relationshipTypes.find((item) => item.Item.Name === 'None').Selected = false;
            this.assignSelectedValues();
        }

        if (this.subissues && this.subissues.length) {
            this.filterSubissueOptions();
        }
        this.valueChange.emit();
    }

    filterSubissueOptions(): void {
        this.subissueRelationshipTypes[0] = this.getFilteredRelationshipTypes(0);
        if (this.subissues[1]) {
            this.subissueRelationshipTypes[1] = this.getFilteredRelationshipTypes(1);
        }
    }

    getFilteredRelationshipTypes(issueNo: number): MultiselectItem[] {
        return this.donorRelationshipTypes
            .filter((rt) => this.donorMedicalHistory[rt.Name] === true || rt.Name === 'None')
            .map((rt) => {
                const existingRelationship = this.subissueRelationshipTypes[issueNo].find((srt) => srt.Item.Id === rt.Id);
                return new MultiselectItem(rt, existingRelationship && existingRelationship.Selected);
            });
    }

    assignSelectedValues(): void {
        for (let item of this.relationshipTypes) {
            if (this.donorMedicalHistory.hasOwnProperty(item.Item.Name)) {
                this.donorMedicalHistory[item.Item.Name] = item.Selected;
            }
        }
    }

    subissueChanged(e, issueNo: number): void {
        if (
            (e.selectedItems.some((item) => item.Name === 'None') || this.donorMedicalHistory.None) &&
            !this.subissues[issueNo].DonorMedicalHistories[0].None
        ) {
            this.subissueRelationshipTypes[issueNo].forEach((item) => (item.Selected = item.Item.Name === 'None'));
            this.subissues[issueNo].DonorMedicalHistories[0].None = true;
        } else {
            this.subissueRelationshipTypes[issueNo][0].Selected = false;
            this.subissues[issueNo].DonorMedicalHistories[0].None = false;
        }
        const selectedItems = this.subissueRelationshipTypes[issueNo].filter((i) => i.Selected).map((i) => i.Item);
        const subissueSelection = {
            issueId: this.subissues[issueNo].Id,
            parentIssueId: this.subissues[issueNo].ParentMedicalIssueId,
            selectedItems: selectedItems,
        };
        this.subissueChange.emit(subissueSelection);
    }

    itemSelected(type: IDonorRelationshipType): boolean {
        if (this.donorMedicalHistory.hasOwnProperty(type.Name)) {
            return this.donorMedicalHistory[type.Name];
        } else {
            return false;
        }
    }

    subissueSelected(type: IDonorRelationshipType, itemNo: number): boolean {
        if (this.subissues[itemNo].DonorMedicalHistories[0].hasOwnProperty(type.Name)) {
            return this.subissues[itemNo].DonorMedicalHistories[0][type.Name];
        } else {
            return false;
        }
    }
}
