import { Component, Input, OnInit } from '@angular/core';

import { IThawing } from '@model/interfaces/thawing';
import { SalesOrderService } from '../../../services/sales-order.service';
import { ThawingService } from '../services/thawing.service';

@Component({
    selector: 'sales-order-thawings',
    templateUrl: './thawings.component.html',
})
export class ThawingsComponent implements OnInit {
    @Input() salesOrderId: number;
    @Input() canEdit: boolean;
    newThawing: IThawing;
    salesOrderThawings: IThawing[] = [];

    constructor(private thawingService: ThawingService, private salesOrderService: SalesOrderService) {}

    ngOnInit(): void {
        this.salesOrderService.GetThawingsBySalesOrderId(this.salesOrderId).subscribe((thawings) => {
            this.salesOrderThawings = thawings;
        });
    }

    addNewThawing(): void {
        this.newThawing = this.thawingService.getEmptyThawing(this.salesOrderId);
        this.salesOrderThawings.unshift(this.newThawing);
    }

    discardNewThaw(): void {
        this.salesOrderThawings.shift();
    }
}
