import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IMetaItem } from '@mt-ng2/base-service';

import { ThawingService } from '../services/thawing.service';
import { ThawingDynamicConfig } from '../thawing.dynamic-config';
import { FollowUpReasonService } from '../services/follow-up-reason.service';
import { ClinicService } from '../../../../clinics/services/clinic.service';
import { SamplePrepMethodService } from '../../../../donors/services/sample-prep-method.service';
import { ReasonsNotToCountService } from '../services/reasons-not-to-count.service';

import { IThawing } from '@model/interfaces/thawing';
import { IClinic } from '@model/interfaces/clinic';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-thawing-basic-info',
    templateUrl: './thawing-basic-info.component.html',
})
export class ThawingBasicInfoComponent implements OnInit {
    @Input() thawing: IThawing;
    @Input() canEdit: boolean;
    @Output() onDiscardNewThaw: EventEmitter<void> = new EventEmitter<void>();

    isEditing = false;
    isHovered: boolean;
    config: any = { formObject: [], viewOnly: [] };
    formFactory: ThawingDynamicConfig<IThawing>;
    doubleClickIsDisabled = false;

    clinics: IClinic[];
    technicians: IMetaItem[];

    form: FormGroup;
    finalizeConfirmOptions: IModalOptions = {
        confirmButtonText: 'Finalize',
        text: 'Details cannot be edited after finalizing',
        type: 'question',
    };

    get isNewThawing(): boolean {
        return this.thawing && this.thawing.Id ? false : true;
    }

    get isFinalized(): boolean {
        return this.thawing?.IsFinalized;
    }

    constructor(
        private thawingService: ThawingService,
        private clinicService: ClinicService,
        private followUpReasonService: FollowUpReasonService,
        private reasonsNotToCountService: ReasonsNotToCountService,
        private samplePrepMethodService: SamplePrepMethodService,
        private notificationsService: NotificationsService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.clinicService.getAll(),
            this.followUpReasonService.getItems(),
            this.reasonsNotToCountService.getItems(),
            this.samplePrepMethodService.getItems(),
        ]).subscribe((answers) => {
            const [clinics, users] = answers;
            this.clinics = clinics;
            this.setConfig();
        });
    }

    setConfig(): void {
        const additionalParams = {
            clinics: this.clinics,
            followUpReasons: this.followUpReasonService.items,
            procedureTypes: this.samplePrepMethodService.items,
            reasonsNotToCount: this.reasonsNotToCountService.items,
            vialTypes: this.samplePrepMethodService.items,
        };
        this.formFactory = new ThawingDynamicConfig<IThawing>(this.thawing, additionalParams);

        if (this.isNewThawing) {
            this.isEditing = true;
            this.config = this.formFactory.getForCreate();
        } else {
            this.config = this.formFactory.getForUpdate();
        }
    }

    onFormCreated(formGroup: FormGroup): void {
        this.form = formGroup;
        formGroup.get('Thawing.Volume').valueChanges.subscribe((value) => {
            this.setTotalMotalCount(formGroup);
        });
        formGroup.get('Thawing.Concentration').valueChanges.subscribe((value) => {
            this.setTotalMotalCount(formGroup);
        });
        formGroup.get('Thawing.Motility').valueChanges.subscribe((value) => {
            this.setTotalMotalCount(formGroup);
        });
    }

    setTotalMotalCount(formGroup: FormGroup): void {
        const vol = formGroup.get('Thawing.Volume').value;
        const concent = formGroup.get('Thawing.Concentration').value;
        const motility = formGroup.get('Thawing.Motility').value;
        const totalMotalCount = (vol || 0) * (concent || 0) * (motility / 100 || 0);
        formGroup.get('Thawing.TotalMotalCount').patchValue(totalMotalCount);
        this.cdr.detectChanges();
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewThawing) {
            this.onDiscardNewThaw.emit();
        } else {
            this.isEditing = false;
        }
    }

    finalizeThawing(value: any): void {
        this.thawing.IsFinalized = true;
        this.formSubmitted(this.form);
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(this.thawing, form.value.Thawing);
            this.saveThawing();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    private saveThawing(): void {
        if (this.isNewThawing) {
            this.thawingService
                .create(this.thawing)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.thawing.Id = answer;
                    this.success();
                });
        } else {
            this.thawingService
                .update(this.thawing)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((success) => {
                    this.success();
                });
        }
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.thawingService.emitChange(this.thawing);
        this.notificationsService.success('Thawing saved successfully.');
    }
}
