<div class="row">
    <div class="col-md-7">
        <h2>
            <mt-page-title pageTitle="Donors"></mt-page-title>
        </h2>
    </div>
</div>
<div>
    <mt-search-bar (onSearch)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="donorStatuses" entity="Donor Status" (onSelectionChanged)="filterSelectionChanged()"> </mt-search-filter-select>
    <mt-search-filter-daterange
        [startDate]="donorCreationStartDate"
        [endDate]="donorCreationEndDate"
        entity="Application Date Created"
        (onSelectionChanged)="dateSelectionChanged($event)"
    ></mt-search-filter-daterange>
    <mt-search-filter-checkbox
        text="Override Screening WorkFlow"
        [value]="overrideScreeningWorkflow"
        (onValueChanged)="toggleScreeningWorkflow($event)"
    ></mt-search-filter-checkbox>
    <div class="pull-right">
        <mt-search-filter-checkbox
            text="Include Inactive Applications"
            [value]="includeInactive"
            (onValueChanged)="toggleInactiveApplications($event)"
        ></mt-search-filter-checkbox>
    </div>
</div>
<br />

<entity-list
    [entities]="donors"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (onPageChanged)="getDonors()"
    (onItemSelected)="donorSelected($event)"
    (onColumnSorted)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</entity-list>
