import { DatePipe } from '@angular/common';
import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';

export class DonorOngoingScreeningsEntityListConfig extends EntityListConfig {
    constructor() {
        const datePipe = new DatePipe('en-US');

        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['BloodDrawDate'],
                    name: 'Screening/Testing Date',
                    pipes: ['date'],
                }),
                new EntityListColumn({
                    accessors: ['BloodWorkStatus', 'Name'],
                    name: 'Blood Work Status',
                }),
                new EntityListColumn({
                    accessors: ['RegulatoryQuestionnaireStatus', 'Name'],
                    name: 'Reg. Questionnaire',
                }),
                new EntityListColumn({
                    accessors: ['PhysicalStatus', 'Name'],
                    name: 'Physical',
                }),
            ],
        };
        super(listConfig);
    }
}
