import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IInventoryPositionDto } from '@model/interfaces/custom/inventory-position-dto';
import { IDonorSample } from '@model/interfaces/donor-sample';
import { IInventory } from '@model/interfaces/inventory';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IModalOptions } from '@mt-ng2/modal-module';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { SamplePrepMethodService } from 'admin-portal/donors/services/sample-prep-method.service';
import { finalize } from 'rxjs/operators';
import { InventoryVesselService } from '../../services/inventory-vessel.service';
import { InventoryDynamicConfig } from '../inventory.dynamic-config';

@Component({
    selector: 'donor-inventory-location-form',
    templateUrl: 'inventory-location-form.component.html',
})
export class InventoryLocationFormComponent implements OnInit {
    @Input() inventory: IInventory;
    @Input() canEdit: boolean;
    @Input() donation: IDonorSample;
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    @Output() onDelete = new EventEmitter<void>();

    inventoryPosition: IInventoryPositionDto;

    isEditing = false;

    config: any = { formObject: [], viewOnly: [] };
    formFactory: InventoryDynamicConfig<IInventory>;
    doubleClickIsDisabled = false;
    viewReady = false;

    showConfirm = false;
    showDelete = false;
    confirmOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        showCloseButton: true,
        showConfirmButton: false,
    };
    deleteOptions: IModalOptions = {
        allowOutsideClick: true,
        customClass: {},
        heightAuto: true,
        icon: 'warning',
        showCloseButton: true,
        showConfirmButton: false,
    };

    get isNewEggInventory(): boolean {
        return this.inventory && this.inventory.Id ? false : true;
    }

    get canDelete(): boolean {
        return this.inventory && !this.inventory.ReservationId && !this.inventory.SalesOrderId;
    }

    constructor(
        private notificationsService: NotificationsService,
        private inventoryService: InventoryVesselService,
        private prepMethodService: SamplePrepMethodService,
    ) {}

    ngOnInit(): void {
        this.prepMethodService.getItems().subscribe(() => {
            if (this.isNewEggInventory) {
                this.setConfig();
            } else {
                this.inventoryService.getInventoryPositionByCane(this.inventory.CaneId).subscribe((invPosition) => {
                    this.inventoryPosition = invPosition;
                    this.setConfig();
                });
            }
        });
    }

    setConfig(): void {
        this.formFactory = new InventoryDynamicConfig<IInventory>(this.inventory, { samplePrepMethods: this.prepMethodService.items });
        if (this.isNewEggInventory) {
            this.config = this.formFactory.getForCreate();
            this.isEditing = true;
        } else {
            this.config = this.formFactory.getForUpdate();
        }
        this.viewReady = true;
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        this.isEditing = false;
        this.onCancel.emit();
    }

    cancelSave(): void {
        this.showConfirm = false;
        this.doubleClickIsDisabled = false;
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.showConfirm = true;
            this.formFactory.assignFormValues(this.inventory, form.value.Inventory);
            this.inventory.CaneId = this.inventoryPosition.Cane.Id;
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error('Save failed.  Please check the form and try again.');
            setTimeout(() => {
                this.doubleClickIsDisabled = false;
            });
        }
    }

    saveEggInventory(): void {
        this.showConfirm = false;
        this.inventoryService
            .saveInventoryLocation(this.inventory)
            .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
            .subscribe((answer) => {
                Object.assign(this.inventory, answer);
                this.success();
            });
    }

    private success(): void {
        this.setConfig();
        this.isEditing = false;
        this.notificationsService.success('Inventory saved successfully.');
    }

    getPrepMethodName(id: number): string {
        return this.prepMethodService.items.find((pm) => pm.Id === id).Name;
    }

    deleteInventory(): void {
        this.onDelete.emit();
        this.showDelete = false;
    }
}
