import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';
import { InventoryDynamicControls, IInventoryDynamicControlsParameters } from '../form-controls/inventory.form-controls';
import { IInventory } from '../interfaces/inventory';

export class InventoryDynamicControlsPartial extends InventoryDynamicControls {
    constructor(inventoryPartial?: IInventory, additionalParameters?: IInventoryDynamicControlsParameters) {
        super(inventoryPartial, additionalParameters);

        (<DynamicField>this.Form.SamplePrepMethodId).labelHtml = '<label>Vial Type</label>';
        (<DynamicLabel>this.View.SamplePrepMethodId).label = 'Vial Type';

        (<DynamicField>this.Form.VialNumber).doNotCreateControl = true;
    }
}
