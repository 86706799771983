import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IDonor } from '../interfaces/donor';
import { ICmvStatus } from '../interfaces/cmv-status';
import { IDonorPsychologicalScreening } from '../interfaces/donor-psychological-screening';
import { IDonorStatus } from '../interfaces/donor-status';
import { IDonorUser } from '../interfaces/donor-user';
import { IMedicalReviewOutcome } from '../interfaces/medical-review-outcome';
import { IUser } from '../interfaces/user';

export interface IDonorDynamicControlsParameters {
    formGroup?: string;
    donorUsers?: IDonorUser[];
    donorStatuses?: IDonorStatus[];
    donorPsychologicalScreenings?: IDonorPsychologicalScreening[];
    publishedModifiedBies?: IUser[];
    medicalReviews?: IMedicalReviewOutcome[];
    medicalReviewModifiedBies?: IUser[];
    cmvStatuses?: ICmvStatus[];
}

export class DonorDynamicControls {

    formGroup: string;
    donorUsers: IDonorUser[];
    donorStatuses: IDonorStatus[];
    donorPsychologicalScreenings: IDonorPsychologicalScreening[];
    publishedModifiedBies: IUser[];
    medicalReviews: IMedicalReviewOutcome[];
    medicalReviewModifiedBies: IUser[];
    cmvStatuses: ICmvStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private donor?: IDonor, additionalParameters?: IDonorDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Donor';
        this.donorUsers = additionalParameters && additionalParameters.donorUsers || undefined;
        this.donorStatuses = additionalParameters && additionalParameters.donorStatuses || undefined;
        this.donorPsychologicalScreenings = additionalParameters && additionalParameters.donorPsychologicalScreenings || undefined;
        this.publishedModifiedBies = additionalParameters && additionalParameters.publishedModifiedBies || undefined;
        this.medicalReviews = additionalParameters && additionalParameters.medicalReviews || undefined;
        this.medicalReviewModifiedBies = additionalParameters && additionalParameters.medicalReviewModifiedBies || undefined;
        this.cmvStatuses = additionalParameters && additionalParameters.cmvStatuses || undefined;

        this.Form = {
            AccountInactive: new DynamicField({
                formGroup: this.formGroup,
                label: 'Account Inactive',
                name: 'AccountInactive',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('AccountInactive') && this.donor.AccountInactive !== null ? this.donor.AccountInactive : false,
            }),
            AcknowledgedWaiver: new DynamicField({
                formGroup: this.formGroup,
                label: 'Acknowledged Waiver',
                name: 'AcknowledgedWaiver',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('AcknowledgedWaiver') && this.donor.AcknowledgedWaiver !== null ? this.donor.AcknowledgedWaiver : false,
            }),
            Active: new DynamicField({
                formGroup: this.formGroup,
                label: 'Active',
                name: 'Active',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('Active') && this.donor.Active !== null ? this.donor.Active : false,
            }),
            Approved: new DynamicField({
                formGroup: this.formGroup,
                label: 'Approved',
                name: 'Approved',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('Approved') && this.donor.Approved !== null ? this.donor.Approved : false,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('Archived') && this.donor.Archived !== null ? this.donor.Archived : false,
            }),
            AvailableOutsideUs: new DynamicField({
                formGroup: this.formGroup,
                label: 'Available Outside Us',
                name: 'AvailableOutsideUs',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('AvailableOutsideUs') && this.donor.AvailableOutsideUs !== null ? this.donor.AvailableOutsideUs : false,
            }),
            CmvStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cmv Status',
                name: 'CmvStatusId',
                options: this.cmvStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.CmvStatusId || null,
            }),
            ComingSoon: new DynamicField({
                formGroup: this.formGroup,
                label: 'Coming Soon',
                name: 'ComingSoon',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('ComingSoon') && this.donor.ComingSoon !== null ? this.donor.ComingSoon : false,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.donor && this.donor.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.DateModified || null,
            }),
            DenialReasonId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Denial Reason',
                name: 'DenialReasonId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.DenialReasonId || null,
            }),
            DonorFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor Fee',
                name: 'DonorFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.DonorFee || null,
            }),
            DonorPsychologicalScreeningId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor Psychological Screening',
                name: 'DonorPsychologicalScreeningId',
                options: this.donorPsychologicalScreenings,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.DonorPsychologicalScreeningId || null,
            }),
            DonorScore: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor Score',
                name: 'DonorScore',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.DonorScore || null,
            }),
            DonorStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor Status',
                name: 'DonorStatusId',
                options: this.donorStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.DonorStatusId || 1,
            }),
            DonorUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Donor User',
                name: 'DonorUserId',
                options: this.donorUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.donor && this.donor.DonorUserId || null,
            }),
            Icsi: new DynamicField({
                formGroup: this.formGroup,
                label: 'Icsi',
                name: 'Icsi',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('Icsi') && this.donor.Icsi !== null ? this.donor.Icsi : false,
            }),
            IsTemporarilyInactive: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Temporarily Inactive',
                name: 'IsTemporarilyInactive',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('IsTemporarilyInactive') && this.donor.IsTemporarilyInactive !== null ? this.donor.IsTemporarilyInactive : false,
            }),
            Iui: new DynamicField({
                formGroup: this.formGroup,
                label: 'Iui',
                name: 'Iui',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('Iui') && this.donor.Iui !== null ? this.donor.Iui : false,
            }),
            Ivf: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ivf',
                name: 'Ivf',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('Ivf') && this.donor.Ivf !== null ? this.donor.Ivf : false,
            }),
            ManualDenialReason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Manual Denial Reason',
                name: 'ManualDenialReason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(255) ],
                validators: { 'maxlength': 255 },
                value: this.donor && this.donor.hasOwnProperty('ManualDenialReason') && this.donor.ManualDenialReason !== null ? this.donor.ManualDenialReason.toString() : '',
            }),
            MedicalReviewId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Medical Review',
                name: 'MedicalReviewId',
                options: this.medicalReviews,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.MedicalReviewId || null,
            }),
            MedicalReviewModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Medical Review Modified By',
                name: 'MedicalReviewModifiedById',
                options: this.medicalReviewModifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.MedicalReviewModifiedById || null,
            }),
            MedicalReviewModifiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Medical Review Modified Date',
                name: 'MedicalReviewModifiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.MedicalReviewModifiedDate || null,
            }),
            OverrideScreeningWorkflow: new DynamicField({
                formGroup: this.formGroup,
                label: 'Override Screening Workflow',
                name: 'OverrideScreeningWorkflow',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('OverrideScreeningWorkflow') && this.donor.OverrideScreeningWorkflow !== null ? this.donor.OverrideScreeningWorkflow : false,
            }),
            PermanentlyInactive: new DynamicField({
                formGroup: this.formGroup,
                label: 'Permanently Inactive',
                name: 'PermanentlyInactive',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('PermanentlyInactive') && this.donor.PermanentlyInactive !== null ? this.donor.PermanentlyInactive : false,
            }),
            Published: new DynamicField({
                formGroup: this.formGroup,
                label: 'Published',
                name: 'Published',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('Published') && this.donor.Published !== null ? this.donor.Published : false,
            }),
            PublishedModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Published Modified By',
                name: 'PublishedModifiedById',
                options: this.publishedModifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.PublishedModifiedById || null,
            }),
            PublishedModifiedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Published Modified Date',
                name: 'PublishedModifiedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.PublishedModifiedDate || null,
            }),
            PublishedToRecipientPortal: new DynamicField({
                formGroup: this.formGroup,
                label: 'Published To Recipient Portal',
                name: 'PublishedToRecipientPortal',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.donor && this.donor.hasOwnProperty('PublishedToRecipientPortal') && this.donor.PublishedToRecipientPortal !== null ? this.donor.PublishedToRecipientPortal : false,
            }),
        };

        this.View = {
            AccountInactive: new DynamicLabel(
                'Account Inactive',
                this.donor && this.donor.hasOwnProperty('AccountInactive') && this.donor.AccountInactive !== null ? this.donor.AccountInactive : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            AcknowledgedWaiver: new DynamicLabel(
                'Acknowledged Waiver',
                this.donor && this.donor.hasOwnProperty('AcknowledgedWaiver') && this.donor.AcknowledgedWaiver !== null ? this.donor.AcknowledgedWaiver : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Active: new DynamicLabel(
                'Active',
                this.donor && this.donor.hasOwnProperty('Active') && this.donor.Active !== null ? this.donor.Active : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Approved: new DynamicLabel(
                'Approved',
                this.donor && this.donor.hasOwnProperty('Approved') && this.donor.Approved !== null ? this.donor.Approved : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Archived: new DynamicLabel(
                'Archived',
                this.donor && this.donor.hasOwnProperty('Archived') && this.donor.Archived !== null ? this.donor.Archived : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            AvailableOutsideUs: new DynamicLabel(
                'Available Outside Us',
                this.donor && this.donor.hasOwnProperty('AvailableOutsideUs') && this.donor.AvailableOutsideUs !== null ? this.donor.AvailableOutsideUs : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            CmvStatusId: new DynamicLabel(
                'Cmv Status',
                getMetaItemValue(this.cmvStatuses, this.donor && this.donor.hasOwnProperty('CmvStatusId') && this.donor.CmvStatusId !== null ? this.donor.CmvStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            ComingSoon: new DynamicLabel(
                'Coming Soon',
                this.donor && this.donor.hasOwnProperty('ComingSoon') && this.donor.ComingSoon !== null ? this.donor.ComingSoon : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            DateCreated: new DynamicLabel(
                'Date Created',
                this.donor && this.donor.DateCreated || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DateModified: new DynamicLabel(
                'Date Modified',
                this.donor && this.donor.DateModified || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            DenialReasonId: new DynamicLabel(
                'Denial Reason',
                this.donor && this.donor.DenialReasonId || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            ),
            DonorFee: new DynamicLabel(
                'Donor Fee',
                this.donor && this.donor.DonorFee || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonorPsychologicalScreeningId: new DynamicLabel(
                'Donor Psychological Screening',
                getMetaItemValue(this.donorPsychologicalScreenings, this.donor && this.donor.hasOwnProperty('DonorPsychologicalScreeningId') && this.donor.DonorPsychologicalScreeningId !== null ? this.donor.DonorPsychologicalScreeningId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonorScore: new DynamicLabel(
                'Donor Score',
                this.donor && this.donor.DonorScore || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonorStatusId: new DynamicLabel(
                'Donor Status',
                getMetaItemValue(this.donorStatuses, this.donor && this.donor.hasOwnProperty('DonorStatusId') && this.donor.DonorStatusId !== null ? this.donor.DonorStatusId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            DonorUserId: new DynamicLabel(
                'Donor User',
                getMetaItemValue(this.donorUsers, this.donor && this.donor.hasOwnProperty('DonorUserId') && this.donor.DonorUserId !== null ? this.donor.DonorUserId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            Icsi: new DynamicLabel(
                'Icsi',
                this.donor && this.donor.hasOwnProperty('Icsi') && this.donor.Icsi !== null ? this.donor.Icsi : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            IsTemporarilyInactive: new DynamicLabel(
                'Is Temporarily Inactive',
                this.donor && this.donor.hasOwnProperty('IsTemporarilyInactive') && this.donor.IsTemporarilyInactive !== null ? this.donor.IsTemporarilyInactive : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Iui: new DynamicLabel(
                'Iui',
                this.donor && this.donor.hasOwnProperty('Iui') && this.donor.Iui !== null ? this.donor.Iui : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Ivf: new DynamicLabel(
                'Ivf',
                this.donor && this.donor.hasOwnProperty('Ivf') && this.donor.Ivf !== null ? this.donor.Ivf : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            ManualDenialReason: new DynamicLabel(
                'Manual Denial Reason',
                this.donor && this.donor.hasOwnProperty('ManualDenialReason') && this.donor.ManualDenialReason !== null ? this.donor.ManualDenialReason.toString() : '',
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            ),
            MedicalReviewId: new DynamicLabel(
                'Medical Review',
                getMetaItemValue(this.medicalReviews, this.donor && this.donor.hasOwnProperty('MedicalReviewId') && this.donor.MedicalReviewId !== null ? this.donor.MedicalReviewId : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MedicalReviewModifiedById: new DynamicLabel(
                'Medical Review Modified By',
                getMetaItemValue(this.medicalReviewModifiedBies, this.donor && this.donor.hasOwnProperty('MedicalReviewModifiedById') && this.donor.MedicalReviewModifiedById !== null ? this.donor.MedicalReviewModifiedById : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            MedicalReviewModifiedDate: new DynamicLabel(
                'Medical Review Modified Date',
                this.donor && this.donor.MedicalReviewModifiedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            OverrideScreeningWorkflow: new DynamicLabel(
                'Override Screening Workflow',
                this.donor && this.donor.hasOwnProperty('OverrideScreeningWorkflow') && this.donor.OverrideScreeningWorkflow !== null ? this.donor.OverrideScreeningWorkflow : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            PermanentlyInactive: new DynamicLabel(
                'Permanently Inactive',
                this.donor && this.donor.hasOwnProperty('PermanentlyInactive') && this.donor.PermanentlyInactive !== null ? this.donor.PermanentlyInactive : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            Published: new DynamicLabel(
                'Published',
                this.donor && this.donor.hasOwnProperty('Published') && this.donor.Published !== null ? this.donor.Published : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
            PublishedModifiedById: new DynamicLabel(
                'Published Modified By',
                getMetaItemValue(this.publishedModifiedBies, this.donor && this.donor.hasOwnProperty('PublishedModifiedById') && this.donor.PublishedModifiedById !== null ? this.donor.PublishedModifiedById : null),
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            ),
            PublishedModifiedDate: new DynamicLabel(
                'Published Modified Date',
                this.donor && this.donor.PublishedModifiedDate || null,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            ),
            PublishedToRecipientPortal: new DynamicLabel(
                'Published To Recipient Portal',
                this.donor && this.donor.hasOwnProperty('PublishedToRecipientPortal') && this.donor.PublishedToRecipientPortal !== null ? this.donor.PublishedToRecipientPortal : false,
                new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            ),
        };

    }
}
